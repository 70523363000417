import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import Config from '../helpers/Config'
import { userActions } from '../stateManagment/features/userSlice';
import { cartActions } from '../stateManagment/features/cartSlice';
import { Mutex } from 'async-mutex'

const mutex = new Mutex()
// Create our baseQuery instance
const baseQuery = (baseUrl: string) => fetchBaseQuery({
    baseUrl,
    responseHandler: async (response) => {
        const data = await response.json()
        if (response.ok) {
            try {
                data.data = JSON.parse(data.data);
            } catch (e) {
                console.log(e);
            }
        }
        return data
    },

    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        try {
            const { UserID: userid, Login: login, CartID: cartId, EcomCustNo: ecomCustNo, JWTToken: token, CustDefaultSc: defaultSc, CustHqAccount: hqAccount, BECustNo: beCustNumber, CompanyNumber: companyNbr, EmployeeLocation: consumerLocation } = (getState() as any).userReducer.user
            if (userid) {
                headers.set('UserID', userid)
            }
            if (cartId) {
                headers.set('CartID', cartId)
            }
            if (ecomCustNo) {
                headers.set('EcomCustNo', ecomCustNo)
            }
            if (defaultSc) {
                headers.set('SiteDefaultSC', defaultSc)
            }
            if (hqAccount) {
                headers.set('SiteHqAccount', hqAccount)
            }
            if (beCustNumber) {
                headers.set('BECustNo', beCustNumber)
            }
            if (companyNbr) {
                headers.set('CompanyNumber', companyNbr.toString())
            }
            if (consumerLocation) {
                headers.set('ConsumerLocation', consumerLocation)
            }
            //Set the sizing mode
            const bSizing  = localStorage.getItem('toggleSizing');
            if(bSizing && bSizing.toString()==="true")
                headers.set('SizingMode',"true");
            else
                headers.set('SizingMode',"false");

            headers.set('Token', token ?? "")
            headers.set('Login', login ?? "")
        } catch (e) {
            console.log(e)
        }

        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');
    },
    validateStatus: (response: any, body: any) => {
        return body.statusCode === 200 && response.status === 200;
    },
})

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = retry(
    async (args, api, extraOptions) => {
        await mutex.waitForUnlock()
        const apiBase = (Config.ENV_ADMIN_BASE_URL ?? Config.ADMIN_BASE_URL) + Config.DYNAMIC_METHOD_SUB_URL
        if (args && (
            args.url == Config.END_POINT_NAMES.ADD_TO_CART ||
            args.url == Config.END_POINT_NAMES.CALCULATE_SHIPPING ||
            args.url == Config.END_POINT_NAMES.CHANGE_LINE_QUANTITY ||
            args.url == Config.END_POINT_NAMES.CONFIRM_ORDER ||
            args.url == Config.END_POINT_NAMES.EDIT_LINE_ITEM_PRODUCT ||
            args.url == Config.END_POINT_NAMES.GET_CART ||
            args.url == Config.END_POINT_NAMES.GET_CART_DETAIL ||
            args.url == Config.END_POINT_NAMES.GET_SHIPPING_METHODS ||
            args.url == Config.END_POINT_NAMES.PLACE_ORDER ||
            args.url == Config.END_POINT_NAMES.REMOVE_FROM_CART)) {
            const { CartID: cartId } = (api.getState() as any).userReducer.user
            if (!cartId) {
                api.dispatch(userActions.logOutUser({}))
                return {
                    error: {
                        status: '',
                        data: {
                            message: ''
                        }
                    }
                }
            }
        }

        let result = await baseQuery(apiBase)(args, api, extraOptions) as any
        if (result.error && result.error.data?.statusCode === 501) {
            let regex = Config.ERROR_MESSAGES.HTTP501_NO_BASKET_REGEX
            if (!mutex.isLocked()) {
                const release = await mutex.acquire()
                try {
                    if (regex.test(result.error.data?.errorMessage)) {
                        const newCart = await baseQuery(apiBase)(typeof args === 'string' ? Config.END_POINT_NAMES.CREATE_BASKET : {
                            ...args,
                            url: Config.END_POINT_NAMES.CREATE_BASKET
                        }, api, extraOptions) as any

                        if (newCart && newCart.data && newCart.data.data) {
                            api.dispatch(cartActions.setCart(newCart.data.data))
                            api.dispatch(userActions.rotateCart(newCart.data.data.id))

                            // retry the initial query
                            result = await baseQuery(apiBase)(args, api, extraOptions)
                        }
                    } else if (Config.ERROR_MESSAGES.HTTP501_CARTID_MISSING === result.error.data?.errorMessage ||
                        Config.ERROR_MESSAGES.HTTP501_UNKNOWN_BASKET_ERROR === result.error.data?.errorMessage) {
                        api.dispatch(userActions.logOutUser({ flashMessage: Config.ERROR_MESSAGES.HTTP501_UNKNOWN_BASKET_ERROR_MSG }))
                        retry.fail(result.error)
                    } else {
                        return {
                            error: {
                                status: result.error?.data.statusCode,
                                data: {
                                    message: result.error?.data.errorMessage,
                                }
                            }
                        }
                    }
                } finally {
                    // release must be called once the mutex should be released again.
                    release()
                }
            }
        } else if (result.error && result.error.data?.statusCode === 401) {
            if (!mutex.isLocked()) {
                const release = await mutex.acquire()
                try {
                    // const refreshResult = await baseQuery(apiBase)(typeof args === 'string' ? Config.END_POINT_NAMES.REFRESH_TOKEN : {
                    //     ...args,
                    //     url: Config.END_POINT_NAMES.REFRESH_TOKEN
                    // }, api, extraOptions) as any
                    // const token = refreshResult.data.data?.[0].JWTToken
                    // if (token) {
                    //     api.dispatch(userActions.setNewToken(token))
                    //     // retry the initial query
                    //     result = await baseQuery(apiBase)(args, api, extraOptions)
                    // } else {
                    api.dispatch(userActions.logOutUser({}))
                    retry.fail(result.error)
                    // }
                } finally {
                    // release must be called once the mutex should be released again.
                    release()
                }
            } else {
                // wait until the mutex is available without locking it
                await mutex.waitForUnlock()
                result = await baseQuery(apiBase)(args, api, extraOptions)
            }
        } else if (result.error && result.error.status === 'FETCH_ERROR') {
            console.log('Backend offline...')
            retry.fail(result.error)
        } else if (result.error && result.error.data?.statusCode !== 200) {
            return {
                error: {
                    status: result.error?.data.statusCode,
                    data: {
                        message: result.error?.data.errorMessage,
                    }
                }
            }
        }
        return result
    },
    { maxRetries: 0 }
)

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded 
 */
export const api = createApi({
    /**
     * `reducerPath` is optional and will not be required by most users.
     * This is useful if you have multiple API definitions,
     * e.g. where each has a different domain, with no interaction between endpoints.
     * Otherwise, a single API definition should be used in order to support tag invalidation,
     * among other features
     */
    reducerPath: 'adminApi',
    /**
     * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
     */
    baseQuery: baseQueryWithReauth,
    /**
     * Tag types must be defined in the original API definition
     * for any tags that would be provided by injected endpoints
     */
    tagTypes: ['Product', 'Products', 'Cart', 'ProductHowToMeasure', 'SiteInfo', 'HomeBanner', 'ShippingMethods', 'PaymentMethods', 'Locales', 'Categories', 'SmartySuggestions', 'CustomerAddresses', 'User', 'ProductAttributes','LoginExt'],
    /**
     * This api has endpoints injected in adjacent files,
     * which is why no endpoints are shown below.
     * If you want all endpoints defined in the same file, they could be included here instead
     */
    endpoints: () => ({})
})

export const enhancedApi = api.enhanceEndpoints({
    endpoints: () => ({
        getProduct: () => 'test',
    }),
})
