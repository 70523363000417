import { createSlice } from '@reduxjs/toolkit';
import { productApi } from '../../services/product';

const defaultRefinements = {
    brand: [],
    c_axDepartment: [],
    c_length: [],
    c_size: [],
    c_gender: [],
    cgid: []
}

const plpInitialState = {
    searchTerm: '',
    paginationData: {
        currentPage: 1,
        pageSize: 24,
        sortBy: ''
    },
    totalRecords: 0,
    products: [],
    refinements: defaultRefinements,
    resultsLayout: 'row-cols-md-3',
    resultsMobileLayout: 'row-cols-2',
    availableRefinements: {},
    showPagination: false,
    searchPerformed: false
}

export const plpSclice = createSlice({
    name: 'plp',
    initialState: plpInitialState,
    reducers: {
        reset: () => {
            return plpInitialState;
        },
        setResultsLayout: (state, action) => {
            state.resultsLayout = action.payload;
        },
        setResultsMobileLayout: (state, action) => {
            state.resultsMobileLayout = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.paginationData.currentPage = action.payload;
        },
        setSortByFilter: (state, action) => {
            state.paginationData.currentPage = 1;
            state.paginationData.sortBy = action.payload;
        },
        setPageSize: (state, action) => {
            state.paginationData.currentPage = 1;
            state.paginationData.pageSize = action.payload;
        },
        setTotalRecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        setRefinement: (state, action) => {
            state.paginationData.currentPage = 1;
            state.refinements[action.payload.type] = action.payload.value;
        },
        resetRefinement: (state, action) => {
            state.paginationData.currentPage = 1;
            state.refinements = defaultRefinements;
        },
        setCategoryRefinements: (state, action) => {
            state.paginationData.currentPage = 1;
            state.refinements = {
                ...defaultRefinements,
                cgid: action.payload
            }
        },
        setBrandRefinements: (state, action) => {
            state.refinements.brand = action.payload;
        },
        setClassRefinements: (state, action) => {
            state.refinements.classes = action.payload;
        },
        setSizeRefinements: (state, action) => {
            state.refinements.sizes = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(productApi.endpoints.getProducts.matchFulfilled, (state, action) => {
                const { respObject, ProductClass, ProductBrands, refinements } = action.payload;
                state.searchPerformed = true;
                state.products = respObject.map((item) => {
                    return {
                        ...item,
                        colorSwatches: item?.ProductColorsJson?.map((color) => ({ code: color?.ColorID, hex: color?.HexCode, imageUrl: color?.AttachmentURL, hoverImageUrl: color?.HoverImageURL })) || [],
                        defaultImage: item?.DefaultImage ? {
                            imageUrl: item?.DefaultImage?.AttachmentURL,
                            hoverImageUrl: item?.DefaultImage?.HoverImageURL
                        } : {}
                    };
                });
                state.showPagination = respObject?.length > 0;
                state.totalRecords = respObject?.[0]?.TotalRecords || 0;
                if (refinements?.length > 0) {
                    const availableRefinements = {};
                    refinements.forEach((refinement) => {
                        availableRefinements[refinement.attribute] = refinement;
                    });
                    state.availableRefinements = availableRefinements;
                }
            })
    }
});

export const { setResultsLayout, setCurrentPage, setSortByFilter, setPageSize, setTotalRecords, setCategoryRefinements, setBrandRefinements, setClassRefinements, setSizeRefinements } = plpSclice.actions;
export const plpActions = plpSclice.actions;
export default plpSclice.reducer;
