import { useEffect, useRef, useState } from 'react';
import './web/resources/themeContent/styles/fontawesome.min.css'
import './web/resources/themeContent/styles/animate.min.css'
import './web/resources/themeContent/styles/slick.css'
import './web/resources/themeContent/styles/slick-theme.css'
import 'react-toastify/dist/ReactToastify.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import './web/resources/themeContent/styles/global.scss';
import './web/resources/themeContent/styles/style-arabic.css';
import { routerConfig } from './RouteConfig';
import { getLanguageCodeFromSession } from './helpers/CommonHelper';
import Config from './helpers/Config';
import { useGetSiteInfoQuery, useGetSiteLogoQuery } from './services/site';
import { useAppDispatch } from './stateManagment/reduxStore';
import rootAction from './stateManagment/actions/rootAction';
import { RouterProvider } from 'react-router-dom';
import GoTop from './web/components/layout/GoTop';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Button } from 'reactstrap';
import * as serviceWorkerRegistration from './serviceWorkerRegistration.ts';
import LoadingScreen from './web/components/shared/LoadingScreen.js';
import { ToggleProvider } from './ToggleProvider.js';
import { PerspectiveSwitchModal } from './web/components/modal/PerspectiveSwitchModal.js';

const AppHelmet = () => {
  const dispatch = useAppDispatch();
  const serviceWorkerLoaded = useRef(false);
  const [langCode, setLangCode] = useState('en');
  const { retokenizing } = useSelector(state => state.commonReducer);
  const [serviceWorkerUpdated, setServiceWorkerUpdated] = useState(false);
  useGetSiteLogoQuery();

  useEffect(() => {
    if (serviceWorkerLoaded.current) return;
    serviceWorkerRegistration.register({
      onUpdate: (reg) => {
        setServiceWorkerUpdated(true);
        reg.waiting.postMessage({ type: 'SKIP_WAITING' });
      },
    });

    serviceWorkerLoaded.current = true;
  }, []);

  useEffect(() => {
    dispatch(rootAction.commonAction.setLoading(false));
    dispatch(rootAction.checkoutActions.setIsInOrderReview(false));
    if (retokenizing) {
      dispatch(rootAction.commonAction.isRetokenizing(false));
    }
  }, [dispatch]);

  useEffect(() => {
    try {
      let lnCode = getLanguageCodeFromSession();
      setLangCode(lnCode);
      if (langCode === Config.LANG_CODES_ENUM["Arabic"]) {
        document.documentElement.lang = Config.LANG_CODES_ENUM["Arabic"];
      } else {
        document.documentElement.lang = Config.LANG_CODES_ENUM["English"];
      }
    } catch (error) {
      console.error('An error occurred:', error.message);
      document.documentElement.lang = Config.LANG_CODES_ENUM["English"];
    }
  }, [langCode]);

  return null;
}

function App() {
  const { isLoading: isSiteInfoLoading, data: siteInfoData, isError: isSiteInfoError } = useGetSiteInfoQuery();

  if (isSiteInfoLoading && !siteInfoData) {
    return <div className='text-center'>
      <LoadingScreen loading={true} detailText='Loading...' />
    </div>
  }

  if (isSiteInfoError) {
    // return a centered logo horizontally and vertically with a message that site is not available
    return <div className='text-center d-flex flex-column justify-content-center align-items-center' style={{ height: '100vh' }}>
      <img alt="Uniform Advantage" src="https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dw33f38775/images/footer/uniform-advantage.svg" />
      <h3 className="mt-4">The site is offline. Please try again after sometime. If the problem persists please call our helpdesk/support team at 800.283.8708</h3>
    </div>
  }

  return (
    <>
    <ToggleProvider>
      <AppHelmet />
      <PerspectiveSwitchModal />
      <RouterProvider router={routerConfig} />
      <GoTop scrollStepInPx="3000" delayInMs="0.25" />
      <ToastContainer
        style={{ width: 'auto' }}
        position="bottom-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={1}
      />
    </ToggleProvider>
    </>
  );

}

export default App;
