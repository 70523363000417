import { ProductBit } from './ProductBit';

const ProductsGridTypeOne = ({ productDetailPageForceUpload, productResponse }) => {
    const { respObject: products } = productResponse;

    return (
        <>
            {products?.map((item, idx) =>
                <ProductBit product={item} key={item.ProductId+item.CategoryID+item.colorSwatches?.[0]?.code} productDetailPageForceUpload={productDetailPageForceUpload} />
            )}
        </>
    );

}


export default ProductsGridTypeOne;

