import React, { useEffect, useState } from 'react';
import { Input, FormGroup, Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import rootAction from '../../../stateManagment/actions/rootAction';
import { useGetContentAssetQuery } from '../../../services/content';
import VASTemplateV2 from '../../components/products/VASTemplateV2';

const EmbroiderySelector = ({ embroideryPresets, embPreviewBaseUrl, multiple = false, hexCode }) => {
    const dispatch = useAppDispatch();
    const { data: customEmbroideryContent, isFetching: isCustomEmbroideryContentLoading } = useGetContentAssetQuery('custom-embroidery-content');
    const [selectType, setSelectType] = useState(1);
    const [selectedOption, setSelectedOption] = useState(-1);
    const selectedEmbroideryPresets = useSelector((state) => state.pdpReducer.selectedEmbroideryPresets);
    const siteInfo = useSelector(state => state.commonReducer);

    const handleItemToggle = (itemId) => {
        setSelectedOption(itemId);
        dispatch(rootAction.pdpActions.setRemoveEmbroideryTextValidation());
        if (multiple) {
            if (selectedEmbroideryPresets.includes(itemId)) {
                dispatch(rootAction.pdpActions.setActiveEmbroideryPresets(selectedEmbroideryPresets.filter((item) => item !== itemId)));
            } else {
                dispatch(rootAction.pdpActions.setActiveEmbroideryPresets([...selectedEmbroideryPresets, itemId]));
            }
        } else {
            dispatch(rootAction.pdpActions.setActiveEmbroideryPresets([itemId]));
        }
    };

    useEffect(() => {
        setSelectedOption(selectedEmbroideryPresets[0]);
    }, []);

    useEffect(() => {
        dispatch(rootAction.pdpActions.setCurrentEmbroideryConfig({ currentVas: embroideryPresets.TemplateDescriptor?.Templates[selectedOption]?.VASData, changedVas: [] }));
        dispatch(rootAction.pdpActions.setShowEmbroideryError(false))
    }, [selectedOption]);

    let optionalSelect = null;
    if (embroideryPresets.TemplateDescriptor?.Optional) {
        optionalSelect = (
            <FormGroup check>
                <Input
                    type="radio"
                    id={`embroidery-preset-optional`}
                    checked={(multiple && selectedEmbroideryPresets.includes(-1)) || (!multiple && selectedEmbroideryPresets[0] === -1)}
                    onChange={() => handleItemToggle(-1)}
                />
                <Label for={`embroidery-preset-optional`} check>No Embroidery</Label>
            </FormGroup>
        );
    }

    const renderDropdownItems = () => {
        return (
            <FormGroup>
                <Input
                    type="select"
                    id={`embroidery-select`}
                    onChange={(e) => {
                        e.target.title = e.target.options[e.target.selectedIndex].text;
                        handleItemToggle(e.target.value)
                    }}
                    value={selectedEmbroideryPresets[0]}
                >
                    {embroideryPresets.TemplateDescriptor?.Optional &&
                        <option value={-1}>No Embroidery</option>}
                    {embroideryPresets.TemplateDescriptor?.Templates.map(({ Price: price, TemplateName: name, index: i }) => (
                        <option key={i} value={i}>{name.replace("[[price]]", price)}</option>
                    ))}
                </Input>
            </FormGroup>
        );
    };

    const renderRadioItems = () => {
        return embroideryPresets.TemplateDescriptor?.Templates.map(({ Price: price, TemplateName: name, VASData: vasData, index: i }) => (
            <React.Fragment key={i}>
                <FormGroup check>
                    <Input
                        type="radio"
                        id={`embroidery-preset-${i}`}
                        checked={(multiple && selectedEmbroideryPresets.includes(i)) || (!multiple && selectedEmbroideryPresets[0] === i)}
                        onChange={() => handleItemToggle(i)}
                    />
                    <Label for={`embroidery-preset-${i}`} check>{name.replace("[[price]]", price)}</Label>
                </FormGroup>
                {((multiple && selectedEmbroideryPresets.includes(i)) || (!multiple && selectedEmbroideryPresets[0] === i)) &&
                    <VASTemplateV2
                        key={hexCode}
                        vasData={vasData}
                        VasSelectionIndex={i}
                        ItemChecked={i === 0 && (!embroideryPresets.TemplateDescriptor?.Optional)}
                        PreviewBaseUrl={embPreviewBaseUrl}
                        hexCode={hexCode}
                        readOnly={false}
                    />
                }
            </React.Fragment>
        ));
    };

    if (!embroideryPresets.TemplateDescriptor?.Templates.length) {
        return null;
    } else {
        var parsed = embroideryPresets.TemplateDescriptor?.Templates.length > siteInfo.embChoiceDisplayGreaterThan ?
            siteInfo.embChoiceDisplayGreaterThanOverride : siteInfo.embChoiceDisplayDefault;
        if (parsed != selectType)
            setSelectType(parsed);
    }

    return (
        <div>
            <div className='align-items-center d-flex font-weight-bold mb-2 w-100'>
                <span className="icon icon-embroideryNeedle mr-3"></span>
                Embroidery
            </div>
            <div className="custom-embroidery-content my-2" dangerouslySetInnerHTML={{ __html: customEmbroideryContent?.ContentValue }} />

            {selectType == 1 ?
                <>
                    {optionalSelect}
                    {renderRadioItems()}
                </>
                :
                <>
                    {renderDropdownItems()}
                    {selectedOption > -1 &&
                        <VASTemplateV2
                            key={selectedOption}
                            vasData={embroideryPresets.TemplateDescriptor?.Templates[selectedOption].VASData}
                            VasSelectionIndex={selectedOption}
                            ItemChecked={selectedOption === 0 && (!embroideryPresets.TemplateDescriptor?.Optional)}
                            PreviewBaseUrl={embPreviewBaseUrl}
                            hexCode={hexCode}
                            readOnly={false}
                        />
                    }
                </>
            }
        </div>
    );
};

export default EmbroiderySelector;
