import { createSlice } from '@reduxjs/toolkit'
import { ProductActivity } from '../../types/product'

const initialState: ProductActivity = {
    currentPrice: 0,
    productId: '',
    categoryId: '',
    selectedColor: null,
    selectedColorId: null,
    selectColorHexCode: '',
    selectedSizeId: null,
    selectedEmbroideryPresets: [],
    currentEmbroideryConfig: null,
    customEmbroideryConfig: {},
    hasEmptyEmbroideryText: false,
    embroideryTextvalidation: [],
    selectedEmployee: '',
    quantity: 1,
    previousValidQty: 1,
    productAttributes: [],
    selectedFitType: 'REGULAR',
    colorBasePrice: 0,
    sizeUnselected: false,
    skuBits: ['', '', ''],
    backOrderMessage: '',
    currentInventories: {},
    isEditMode: false,
    lineItemId: '',
    minPrice: 0,
    maxQuantity: 300,
    quantityWarning: false,
    outlineEmbroideryError: false
}

export const pdpSlice = createSlice({
    name: 'pdp',
    initialState,
    reducers: {
        reset: () => {
            return initialState
        },
        setCategoryAndProductId: (state, action) => {
            state.productId = action.payload.productId?.split('-')[0] ?? ''
            state.categoryId = action.payload.categoryId
            state.skuBits[0] = action.payload.productId?.split('-')[0] ?? ''
        },
        setCurrentPrice: (state, action) => {
            state.currentPrice = action.payload
        },
        setActiveHexCode: (state, action) => {
            state.selectColorHexCode = action.payload
        },
        setEmployee: (state, action) => {
            state.selectedEmployee = '';
            if (action.payload && action.payload.FirstName)
                state.selectedEmployee = `${action.payload.FirstName},${action.payload.LastName}`;
        },
        setColorBasePrice: (state, action) => {
            state.colorBasePrice = action.payload
        },
        increaseQuantity: (state, action) => {
            if (state.quantity < action.payload) {
                state.quantity = Number(state.quantity) + 1;
                state.quantityWarning = false
            } else {
                state.quantityWarning = true
            }
        },
        decreaseQuantity: (state, action) => {
            if (state.quantity > action.payload) {
                state.quantity = Number(state.quantity) - 1;
                state.quantityWarning = false
            } else {
                state.quantityWarning = true
            }
        },
        setQuantity: (state, action) => {
            state.maxQuantity = action.payload.maxQuantity;
            if (action.payload.quantity <= action.payload.maxQuantity && action.payload.quantity >= action.payload.minQuantity) {
                state.quantityWarning = false
                state.previousValidQty = Number(action.payload.quantity)
                state.quantity = Number(action.payload.quantity)
            } else if (action.payload.quantity > 300) {
                state.quantity = Number(action.payload.quantity.toString().charAt(0))
                state.quantityWarning = true
                state.quantity = state.previousValidQty
            } else if (!action.payload.quantity) {
                state.quantity = Number(state.previousValidQty)
                state.quantityWarning = true
            } else {
                state.quantityWarning = true
                state.quantity = Number(state.previousValidQty)
            }
        },
        setOptimisticQuantity: (state, action) => {
            state.quantity = action.payload?.toString()?.length > 3 ? Number(action.payload.quantity.toString().charAt(0)) : action.payload.replace(/[^0-9.]/g, '')
        },
        setActiveColor: (state, action) => {
            state.selectedColorId = action.payload?.id
            state.selectedColor = action.payload
            state.skuBits[1] = action.payload?.id
        },
        setBackOrderMessage: (state, action) => {
            state.backOrderMessage = action.payload;
        },
        setActiveSize: (state, action) => {
            state.quantityWarning = false;
            if (!action.payload) {
                state.selectedSizeId = ''
                state.skuBits[2] = ''
                state.currentPrice = 0
                return;
            }

            if (action.payload.orderable || action.payload.backOrderable) {
                state.selectedSizeId = action.payload.id
                state.skuBits[2] = action.payload.id
            } else {
                state.selectedSizeId = ''
                state.skuBits[2] = ''
                state.currentPrice = 0
            }
        },
        setActiveFitType: (state, action) => {
            state.selectedFitType = action.payload
        },
        setActiveEmbroideryPresets: (state, action) => {
            state.selectedEmbroideryPresets = action.payload
        },
        setCurrentEmbroideryConfig: (state, action) => {
            const changedVas = action.payload.changedVas
            const currentVas = action.payload.currentVas
            state.currentEmbroideryConfig = currentVas?.map((vas: any) => {
                const foundVas = changedVas.find((changedVas: any) => changedVas.clientId === vas.clientId)
                return foundVas ? foundVas : vas
            })
        },
        setCustomEmbroideryConfig: (state, action) => {
            if (!action.payload) {
                state.customEmbroideryConfig = {};
                return;
            }
            state.customEmbroideryConfig[action.payload.id] = action.payload;
        },
        enableEditMode: (state) => {
            state.isEditMode = true
        },
        setMinPrice: (state, action) => {
            state.minPrice = action.payload
        },
        setHasEmptyEmbroideryText: (state, action) => {
            const index = action.payload.index
            state.embroideryTextvalidation = [...new Array(Math.max(state.embroideryTextvalidation?.length ?? 0, index+1))].map((_, i) => {
                if(index === i) {
                    return action.payload.hasEmptyEmbroideryText
                }else {
                    return state.embroideryTextvalidation?.[i] ?? false
                }
            })
            state.hasEmptyEmbroideryText = state.embroideryTextvalidation.find((item: boolean) => item) ?? false
        },
        setRemoveEmbroideryTextValidation: (state) => {
            state.embroideryTextvalidation = [];
            state.hasEmptyEmbroideryText = false;
        },
        setShowEmbroideryError: (state, action) => {
            state.outlineEmbroideryError = action.payload
        }
    }
})

export const { reset } = pdpSlice.actions
export const pdpActions = pdpSlice.actions
export default pdpSlice.reducer
