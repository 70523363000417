import { useEffect, useState, useContext } from 'react';
import { Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';

import { useSelector, useDispatch } from 'react-redux';
import rootAction from '../../../stateManagment/actions/rootAction';
import { makeProductShortDescription } from '../../../helpers/ConversionHelper';
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import _ from 'lodash';
import { ToggleContext } from '../../../ToggleProvider';

function CollapsibleSection({ item, allowMultipleOpen = false, refinementType }) {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(true);
    const { refinements: plpActiveRefinenemnts } = useSelector(state => state.plpReducer);
    const params = useParams();
    const navigate = useNavigate();
    const toggleOpen = () => setIsOpen(!isOpen);

    const regEx = new RegExp(/\s/g);

    const getToggleIcon = (visible) => {
        return visible ? <i className='fa fa-chevron-up' /> : <i className='fa fa-chevron-down' />;
    };

    const handleActiveRefinements = (refinement) => {
        const currentRefinement = plpActiveRefinenemnts[refinementType] || [];
        // allow multiple or not
        if (allowMultipleOpen) {
            // if it's already in the array, remove it
            if (currentRefinement.includes(refinement)) {
                dispatch(rootAction.plpActions.setRefinement({ type: refinementType, value: currentRefinement.filter((ref) => ref !== refinement) }));
            } else {
                dispatch(rootAction.plpActions.setRefinement({ type: refinementType, value: [...currentRefinement, refinement] }));
                // setActiveRefinements([...activeRefinements, refinement]);
            }
        } else {
            // toggle the active refinement
            let value = currentRefinement[0] === refinement ? null : refinement;
            // setActiveRefinement(value);
            dispatch(rootAction.plpActions.setRefinement({ type: refinementType, value: [value] }));
        }
        // Set page# 1 in URL
        navigate('/all-products/' + params.parent_category_id + '/' + params.category_id + '/' + 1);
    }

    const shouldBeChecked = (refinement) => (allowMultipleOpen && plpActiveRefinenemnts[refinementType] && plpActiveRefinenemnts[refinementType].includes(refinement)) || (plpActiveRefinenemnts[refinementType] && plpActiveRefinenemnts[refinementType][0] === refinement);

    return (
        <Card id={`refinement-${item?.attribute}`} className={`refinement refinement-${item?.attribute}`}>
            <CardHeader color="link" onClick={toggleOpen}>
                {item?.label} {getToggleIcon(isOpen)}
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody className='has-custom-scrollbar'>
                    <ul className="list-unstyled">
                        {item?.options?.filter(option => option.hit_count > 0).map((r, idx) =>
                            <li key={idx} className={`item item-${item?.attribute}`}>
                                <div className="form-group custom-control custom-checkbox">
                                    {item?.attribute === 'c_color' && r?.hex ? 
                                    <>
                                        <input type="checkbox" className={`custom-control-input${r.hex.toUpperCase() === '#FFFFFF' ? ' swatch-white' : ''}`} checked={shouldBeChecked(r.id) || false} id={`refinement-cb-${r.id.replaceAll(regEx, '-').toLowerCase()}`} onChange={() => handleActiveRefinements(r.id)} style={r.hex.split("_").length > 1 ? { background: 'linear-gradient(to right,' + r.hex.split("_")[0] + ' 70%, ' + r.hex.split("_")[1] + ' 0)' } : { backgroundColor: r.hex }} />
                                    </>
                                    :
                                    <>
                                        <input type="checkbox" className="custom-control-input" checked={shouldBeChecked(r.id) || false} id={`refinement-cb-${r.id.replaceAll(regEx, '-').toLowerCase()}`} onChange={() => handleActiveRefinements(r.id)} />
                                    </>
                                    }
                                    <label className="custom-control-label text-body" htmlFor={`refinement-cb-${r.id.replaceAll(regEx, '-').toLowerCase()}`}>{r.value} ({r.hit_count})</label>
                                </div>
                            </li>
                        )}
                    </ul>
                </CardBody>
            </Collapse>
        </Card>
    );
}

function SelectedFilter({ refinementType, item, activeRefinements, allowMultipleOpen = false }) {
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const handleActiveRefinements = (refinement) => {
        const currentRefinement = activeRefinements?.filter(item => item !== refinement) || [];
        // allow multiple or not
        if (allowMultipleOpen) {
            // if it's already in the array, remove it
            if (currentRefinement.includes(refinement)) {
                dispatch(rootAction.plpActions.setRefinement({ type: refinementType, value: currentRefinement.filter((ref) => ref !== refinement) }));
            } else {
                dispatch(rootAction.plpActions.setRefinement({ type: refinementType, value: [...currentRefinement, refinement] }));
                // setActiveRefinements([...activeRefinements, refinement]);
            }
        } else {
            // toggle the active refinement
            let value = currentRefinement[0] === refinement ? null : refinement;
            // setActiveRefinement(value);
            dispatch(rootAction.plpActions.setRefinement({ type: refinementType, value: currentRefinement }));
        }
        // Set page# 1 in URL
        navigate('/all-products/' + params.parent_category_id + '/' + params.category_id + '/' + 1);
    }

    return (

        <>
            {Object.keys(activeRefinements || {}).length > 0 ?
                <>
                    {activeRefinements.map((item, idx) => {
                        return (
                            <li className='filter-value' key={refinementType+item}>
                            <button className='btn btn-link' onClick={() => handleActiveRefinements(item)}>{item}</button>
                            </li>
                        );
                    })}
                </>
                : null}
        </>
    );
}

export const SiteLeftSidebarFilter = ({ totalRecords, windowInnerWidth }) => {
    const { availableRefinements, refinements: plpActiveRefinenemnts } = useSelector((state) => state.plpReducer);
    const [, setLocalizationLabelsArray] = useState([]);
    const [, setLangCode] = useState('');
    const dispatch = useAppDispatch();
    const { isToggled, setIsToggled } = useContext(ToggleContext);

    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {

            //--Get language code
            let lnCode = getLanguageCodeFromSession();
            setLangCode(lnCode);

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["SiteLeftSidebarFilter"], null);
            if (arryRespLocalization != null && arryRespLocalization !== undefined && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);
    }, [])

    return (
        <>
            <div className='refinements col-md-4'>
                <div id='refinements-wrapper'>
                    {windowInnerWidth < 992 ?
                        <>
                            <div className='result-count row align-items-center justify-content-between'>
                                <div className='col-auto text-left'>
                                    <div className='d-flex align-items-center'>
                                        <span className='items-count'>{totalRecords > 1 ? `${totalRecords} items` : `${totalRecords} item`}</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Button className='clear-refinements link-underline' onClick={(e) => dispatch(rootAction.plpActions.resetRefinement())} color="link" aria-label='Clear All'>
                                        Clear All
                                    </Button>
                                </div>
                            </div>
                        </>
                        :
                        null
                    }
                    <div className='mobile-refinement-wrap'>
                        {Object.keys(availableRefinements).filter(
                            (refinement) => {
                                return availableRefinements[refinement].options?.length > 1 || (availableRefinements[refinement].options?.length === 1 && availableRefinements[refinement].options[0].hit_count !== totalRecords) || (availableRefinements[refinement].options?.length === 1 && plpActiveRefinenemnts?.[refinement]?.length > 0)
                            }
                        ) &&
                            <>
                                <h3 className='yourselection hidden-md-down'>Your Selection</h3>
                                <ul>
                                    {Object.keys(availableRefinements).filter(
                                        (refinement) => {
                                            return availableRefinements[refinement].options?.length > 1 || (availableRefinements[refinement].options?.length === 1 && availableRefinements[refinement].options[0].hit_count !== totalRecords) || (availableRefinements[refinement].options?.length === 1 && plpActiveRefinenemnts?.[refinement]?.length > 0)
                                        }
                                    ).map((item, idx) => (
                                        <SelectedFilter key={item.id} refinementType={item} item={availableRefinements[item]} activeRefinements={plpActiveRefinenemnts[item]} />
                                    ))}
                                </ul>
                            </>
                        }
                    </div>
                    <div className='accordion-cards'>
                        {Object.keys(availableRefinements).filter(
                            isToggled ?
                                (refinement) => {
                                    return (availableRefinements[refinement].options?.length > 1 || (availableRefinements[refinement].options?.length === 1 && availableRefinements[refinement].options[0].hit_count !== totalRecords) || (availableRefinements[refinement].options?.length === 1 && plpActiveRefinenemnts?.[refinement]?.length > 0)) && availableRefinements[refinement].attribute !== "c_axDepartment"
                                } : (refinement) => {
                                    return availableRefinements[refinement].options?.length > 1 || (availableRefinements[refinement].options?.length === 1 && availableRefinements[refinement].options[0].hit_count !== totalRecords) || (availableRefinements[refinement].options?.length === 1 && plpActiveRefinenemnts?.[refinement]?.length > 0)
                                }
                        ).map((item, idx) => (
                            <CollapsibleSection key={item} refinementType={item} item={availableRefinements[item]} allowMultipleOpen={true} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );

}

export const LeftSideBarPopularProducts = () => {
    const dispatch = useDispatch();

    const [PopularProductsList, setPopularProductsList] = useState([]);
    const [adminPanelBaseURL, setBaseUrl] = useState(Config['ENV_ADMIN_BASE_URL'] ?? Config['ADMIN_BASE_URL']);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    const GetPopularProductsForLeftSideBar = async () => {

        const headersPouplarProducts = {
            // customerid: userData?.UserID,
            // customeremail: userData.EmailAddress,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        const paramPouplarProducts = {
            requestParameters: {
                PageNo: 1,
                PageSize: 10,
                recordValueJson: "[]",
            },
        };

        const responsePopularProducts = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_POPULAR_PRODUCTS_LIST'], null, paramPouplarProducts, headersPouplarProducts, "POST", true);
        if (responsePopularProducts != null && responsePopularProducts.data != null) {
            setPopularProductsList(JSON.parse(responsePopularProducts.data.data));
            console.log(JSON.parse(responsePopularProducts.data.data));
        }
    }


    useEffect(() => {

        const GetFiltersAllValues = async () => {

            //--get popular products list
            await GetPopularProductsForLeftSideBar();

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["SiteLeftSidebarFilter"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }

        }

        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));

        // call the function
        GetFiltersAllValues().catch(console.error);

        //--stop loader
        dispatch(rootAction.commonAction.stopLoading());
    }, [])



    return (

        <>
            <div className="collapse-widget aside-products-widget">
                <h3 className="aside-widget-title" id="lbl_lftfilt_pop_prod">
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, "Popular Products", "lbl_lftfilt_pop_prod")
                        :
                        "Popular Products"
                    }
                </h3>



                {
                    PopularProductsList?.map((item, idx) =>

                        <div key={idx} className="aside-single-products">
                            <div className="products-image">
                                <Link to="#">


                                    {
                                        item?.ProductImagesJson?.slice(0, 1).map((img, imgIdx) =>
                                            <>
                                                <img key={imgIdx} src={adminPanelBaseURL + img.AttachmentURL} alt="image" />

                                            </>

                                        )
                                    }

                                </Link>
                            </div>

                            <div className="products-content">
                                {/* <span>
                                    <Link to="#">
                                        Men's
                                    </Link>
                                </span> */}
                                <h3>
                                    <Link
                                        to={`/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${_.kebabCase(item.CategoryName) ?? "shop"}/${_.kebabCase(item.ProductName)}`}
                                    >
                                        {makeProductShortDescription(item.ProductName, 45)}
                                    </Link>
                                </h3>

                                <div className="product-price">
                                    <span className="new-price">


                                        {item.DiscountedPrice != undefined && item.DiscountedPrice > 0 ?
                                            <>
                                                <del style={{ color: "#9494b9" }}>{GetDefaultCurrencySymbol()}{item.Price}</del> &nbsp; {GetDefaultCurrencySymbol()}{item.DiscountedPrice}
                                            </>
                                            :
                                            <>
                                                ${item.Price}
                                            </>

                                        }

                                    </span>
                                    {/* <span className="old-price">$291.00</span> */}
                                </div>
                            </div>
                        </div>

                    )}





            </div>

        </>

    );
}
