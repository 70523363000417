import React, { useEffect, useRef, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import rootAction from "../../../stateManagment/actions/rootAction";
import { Button, Tooltip } from "reactstrap";
import { GetDefaultCurrencySymbol, replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import { blur } from '../../../utils/blur.js';
import { backOrderMessage } from "../../../utils/product.ts";
import { formatCurrency } from '../../../helpers/ConversionHelper.js';
import { ToggleContext } from "../../../ToggleProvider.js";

const adjustPriceHelper = (productAttributes, selectedSizeId, colorId, currentPrice) => {
    const attributesForActivity = productAttributes?.find((productAttribute) =>
        productAttribute.productAttributeId === 2 &&
        (productAttribute.colorId ?? "") === colorId &&
        productAttribute.primaryKeyValue?.toLocaleLowerCase() === selectedSizeId?.toLocaleLowerCase());

    return attributesForActivity?.priceAdjustment ?? currentPrice;
}
// stocklevel > 0 and orderable = in s tock
// !orderable = out of stock
// stocklevel = 0 and orderable = backorder + backorder date (tooltip) + send message to custom pproduct line item
// ats will validate quantity that can be ordered
const SizeButton = ({ size, isLoading, id, selectedSizeId, btnAction }) => {
    const { selectedColorId, currentInventories } = useSelector((state) => state.pdpReducer);
    const [currentInventory, setCurrentInventory] = useState(null);

    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const toggle = () => {
        setTooltipOpen(!tooltipOpen);
    };

    return (
        <>
            <button
                id={id}
                className={`btn ${size?.orderable || size?.backOrderable ? '' : 'disabled'} btn-sm ${(size?.orderable || size?.backOrderable) && size.id === selectedSizeId ? 'active' : ''}`}
                onClick={(e) => blur(e, () => btnAction(size))}>{size.name}</button>
            {!isLoading && <Tooltip
                placement="top"
                isOpen={!isLoading && tooltipOpen}
                target={id}
                toggle={toggle}
                modifiers={{ offset: { offset: '0, 15' } }}
            >
                {size?.backOrderMessage}
            </Tooltip>}
        </>
    )
}

const ProductSizeSelector = ({ sizes, hasError, isLoading, productAttributes, setHasError, isEdit, colorInventory, initialSizeId, productVariationAttributes }) => {
    const selectorWrapperRef = useRef(null);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [sizesEnriched, setSizesEnriched] = useState([]);
    const dispatch = useAppDispatch();
    const { selectedSizeId, currentPrice, selectedColorId } = useSelector((state) => state.pdpReducer);
    const { isToggled, setIsToggled } = useContext(ToggleContext);

    useEffect(() => {
        const hasVariationColorAttribute = productVariationAttributes?.find((productAttribute) => productAttribute.id === 'color');
        if (productAttributes?.length > 0 && selectedSizeId && (selectedColorId || !hasVariationColorAttribute)) {
            dispatch(rootAction.pdpActions.setCurrentPrice(adjustPriceHelper(productAttributes, selectedSizeId, selectedColorId ?? "", currentPrice)));
        }
    }, [currentPrice, dispatch, productAttributes, selectedSizeId, sizes]);

    useEffect(() => {
        if (selectedSizeId !== '') {
            setHasError(false);
        }
    }, [selectedSizeId]);

    useEffect(() => {
        if (selectorWrapperRef.current && hasError) {
            selectorWrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [hasError]);

    useEffect(() => {
        const hasVariationColorAttribute = productVariationAttributes?.find((productAttribute) => productAttribute.id === 'color');
        if (!isLoading && (colorInventory?.length > 0 || !hasVariationColorAttribute)) {
            const enrichedSizes = sizes?.map((size) => {
                const inventory = colorInventory?.find((inv) => inv.sizeId === size.id);
                return {
                    ...size,
                    orderable: inventory?.orderable,
                    backOrderable: inventory?.backOrderable,
                    backOrderMessage: backOrderMessage(inventory || {}),
                }
            });

            const sizeId = !selectedSizeId ? initialSizeId : selectedSizeId;
            if (sizeId && enrichedSizes.length > 0) {
                const size = enrichedSizes?.find((size) => size.id === sizeId);
                if (size) {
                    dispatch(rootAction.pdpActions.setActiveSize({ ...size, id: sizeId }));
                }
            }

            setSizesEnriched(enrichedSizes);
        } else {
            setSizesEnriched(sizes.map((size) => {
                return {
                    ...size,
                    orderable: false,
                    backOrderable: false,
                    backOrderMessage: backOrderMessage({}),
                }
            }));
        }
        // return () => {
        //     setSizesEnriched([]);
        //     dispatch(rootAction.pdpActions.setActiveSize({ id: '' }));
        // }
    }, [colorInventory, isLoading, dispatch]);

    const btnAction = (size) => {
        if (!isEdit) {
            if (size.id === selectedSizeId) {
                dispatch(rootAction.pdpActions.setActiveSize(null));
            } else {
                dispatch(rootAction.pdpActions.setActiveSize(size));
            }
        }
    }

    if (!sizes?.length) return null;

    return (
        <div ref={selectorWrapperRef} className='size-attribute py-2' disabled={isEdit}>
            <div className='size-attribute-title label mb-2'>Size: <span className="spacer-right">{selectedSizeId}</span> {(selectedSizeId && (!isToggled)) ? (<>Price: <span>{formatCurrency(currentPrice)}</span></>) : ''}</div>
            <div className={`size-attribute-content ${hasError ? 'unselected' : ''}`}>
                {sizesEnriched?.map((size, i) => (
                    <SizeButton key={`size-${size.id.replace(/[\W_]+/g, "")}`} btnAction={btnAction} size={size} selectedSizeId={selectedSizeId} id={`size-${size.id.replace(/[\W_]+/g, "")}`} isLoading={isLoading} />
                ))
                }
            </div >
            <div className={`text-danger mt-2 ${hasError ? 'd-block' : 'd-none'}`}>
                {LocalizationLabelsArray ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Please make a selection.", "lbl_prd_det_pleasemakeaselection")
                    :
                    "Please make a selection."
                }
            </div>
        </div>
    );
}

export default ProductSizeSelector;
