import { useSelector } from "react-redux";
import { replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import { formatCurrency } from "../../../helpers/ConversionHelper";
import { useContext } from "react";
import { useGetPaymentMethodsQuery } from "../../../services/checkout";
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import { CheckoutStripForm } from "../../components/cart/CheckoutStripForm";
import { FormGroup, Input, Label } from "reactstrap";
import ContentLoader from "react-content-loader";
import React, { useEffect, useState } from "react";
import rootAction from "../../../stateManagment/actions/rootAction";
import { useGetContentAssetQuery } from "../../../services/content";
import { CARD_MAPPING } from '../../../helpers/Constants';
import { ToggleContext } from "../../../ToggleProvider";
import { useGetCartDataQuery } from '../../../services/cart';

export const PaymentMethod = ({ stripeRef, address }) => {
    const dispatch = useAppDispatch();
    const { isFetching: isCartFetching, data: cartDataFetched } = useGetCartDataQuery(null);
    const { data: customCreditPaymentContent, isFetching: isCustomCreditPaymentContentLoading } = useGetContentAssetQuery('payment-credit-amount');
    const { isFetching: isPaymentMethodFetching } = useGetPaymentMethodsQuery(null);
    const LocalizationLabelsArray = [];
    const { 
        user: {
            CreditBalance: creditBalance,
            PayrollDeduction: payrollDeduction, 
            IsTaxExempt: isTaxExempt,
            IsOnAccount: isOnAccount
        } 
    } = useSelector((state) => state.userReducer);
    const { paymentMethods: availablePaymentMethods, payrollMaxDeductions: maxDeductions } = useSelector((state) => state.checkout);
    const { orderTotal } = useSelector((state) => state.cartReducer);
    const { isToggled, setIsToggled } = useContext(ToggleContext);

    
    
    const { isShippingTaxExempt, isTaxExemptAppliedToMaxItem, listIgnoreTaxExempt } = useSelector(state => state.commonReducer);
    
    const [paymentTotal, setPaymentTotal] = useState(0);
    const [paymentAmounts, setPaymentAmounts] = useState({});

    useEffect(() => {
        if (maxDeductions == 0 && payrollDeduction !== null)
            dispatch(rootAction.checkoutActions.setPayrollMaxDeductions(payrollDeduction.PayrollDeduction));

        if (isCartFetching || availablePaymentMethods?.length === 0)
            return;
        
        let amounts = {};
        const { taxTotal, orderTotal, products, cartSubTotal, shippingMethod, shippingSubTotal } = cartDataFetched;
        if (isTaxExempt) { // tax exempt loop
            let adjustedTax = 0;
            let adjustedPrice = cartSubTotal + shippingSubTotal;
           
            if (!isShippingTaxExempt && shippingMethod?.shippingTax > 0) {
                adjustedTax += shippingMethod?.shippingTax;
                adjustedPrice += shippingMethod?.shippingTax;
            }

            // loop throught items
            let taxApplied = false;
            let remainingAmount = adjustedPrice;
            availablePaymentMethods?.map(method => {
                if (listIgnoreTaxExempt.indexOf(method.id) > -1 && !taxApplied) {
                    if (remainingAmount != adjustedPrice) {
                        // here only if tax exempt payments implemented
                        // recalculate tax from product items
                        var exemptedAmount = adjustedPrice - remainingAmount;
                        [...products]?.sort((a, b) =>
                            isTaxExemptAppliedToMaxItem ? 
                                b?.discountedPrice - a?.discountedPrice : 
                                a?.discountedPrice - b?.discountedPrice
                        ).map(product => {
                            var subTotal = product.discountedPrice;
                            if (exemptedAmount > 0) {
                                if (subTotal > exemptedAmount) {
                                    subTotal -= exemptedAmount;
                                    exemptedAmount = 0;
                                } else if (subTotal < exemptedAmount) {
                                    exemptedAmount -= subTotal;
                                    subTotal = 0;
                                }
                            }
                            adjustedTax += (subTotal * product.taxRate);
                        });
                    }
                    // here if no tax exempt implemented, use existing ordertotal
                    else adjustedTax += taxTotal - shippingMethod?.shippingTax;
                    remainingAmount += adjustedTax;
                    taxApplied = true;
                }
                ({ remainingAmount, amounts } = setPaymentValues(method.id, remainingAmount,amounts));
            });

            dispatch(rootAction.cartActions.setTaxAdjustments({
                adjustedTax,
                adjustedTotal: adjustedPrice + adjustedTax,
            }));
            console.log(adjustedPrice);
            setPaymentTotal(adjustedPrice);
        } else { // default method of calculation
            let remainingAmount = orderTotal;
            setPaymentTotal(orderTotal);
            availablePaymentMethods?.map(method => {
                ({ remainingAmount, amounts } = setPaymentValues(method.id, remainingAmount,amounts));
                console.log('remainingAmount', remainingAmount, amounts);
            });
        }

        setPaymentAmounts(amounts);
        dispatch(rootAction.checkoutActions.setPaymentAmounts(amounts));
    }, [availablePaymentMethods, cartDataFetched]);

    const setPaymentValues = (id, remainingAmount, amounts) => {
        let amount = remainingAmount;
        switch (id) {
            case "UAB_EMPLOYEE_CREDITS": {
                if (creditBalance < remainingAmount) {
                    remainingAmount -= creditBalance;
                    amount = creditBalance;
                } else {
                    remainingAmount = 0;
                }
            } break;
            case "UAB_PAYROLL_DEDUCTION": {
                if (payrollDeduction?.AvailablePDBalance < remainingAmount) {
                    remainingAmount -= payrollDeduction?.AvailablePDBalance
                    amount = payrollDeduction?.AvailablePDBalance;
                } else {
                    remainingAmount = 0;
                }
            } break;
            case "CREDIT_CARD":
            case "ON_ACCOUNT":
            default: {
                remainingAmount = 0;
            } break;
        }

        amounts = {
            ...amounts,
            [id]: amount
        };

        return { remainingAmount, amounts };
    }

    return (
        <>
            <h6>
                {LocalizationLabelsArray.length > 0 ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Payment Method", "lbl_check_paymentmethod")
                    :
                    "Payment Method"
                }
            </h6>
            {!isPaymentMethodFetching && paymentTotal > 0 ?
                (() => {
                    let remainingAmount = paymentTotal;
                    if (availablePaymentMethods?.length === 0) {
                        // no payment methods available feedback
                        return (
                            <FormGroup>
                                <Label className="text-danger">
                                    {LocalizationLabelsArray.length > 0 ?
                                        replaceLoclizationLabel(LocalizationLabelsArray, "No payment methods available. Please contact us for assistance.", "lbl_check_nopaymentmethod")
                                        :
                                        "No payment methods available. Please contact us for assistance."
                                    }
                                </Label>
                            </FormGroup>
                        )
                    }
                    return availablePaymentMethods?.map((paymentMethod, index) => {
                        let amount = paymentAmounts[paymentMethod.id] ?? 0;
                        if (paymentMethod.id === "CREDIT_CARD") {
                            return (
                                <React.Fragment key={paymentMethod.id}>
                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                        <span className='label'>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "Credit Card", "lbl_check_creditcard")
                                                :
                                                "Credit Card"
                                            }:&nbsp;{formatCurrency(amount)}
                                        </span>
                                        <div className="cards d-flex justify-content-center">
                                            {paymentMethod.cards?.map((card, index) => {
                                                return (
                                                    <span key={index} className={`creditcard ${CARD_MAPPING[card.name.toLowerCase()] ?? card.name.toLowerCase()}`}></span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <CheckoutStripForm
                                        ref={stripeRef}
                                        CustBillingAddress={address}
                                    />
                                </React.Fragment>
                            )
                        }
                        if (paymentMethod.id === "UAB_EMPLOYEE_CREDITS") {
                            return (
                                <div className='d-flex flex-wrap align-items-center mb-3' key={paymentMethod.id}>
                                    {customCreditPaymentContent?.ContentValue?.length > 0 ?
                                        <>
                                            <div className='label d-block' style={{ flex: '1 0 100%' }}>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, paymentMethod.name, "lbl_check_creditcard")
                                                    :
                                                    paymentMethod.name
                                                }
                                            </div>
                                            <p className="font-weight-bold">
                                                {customCreditPaymentContent.ContentValue.replace("{amount}", formatCurrency(amount))}
                                            </p>
                                        </>
                                        :
                                        <>
                                            <span className='label'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, paymentMethod.name, "lbl_check_creditcard")
                                                    :
                                                    paymentMethod.name
                                                }
                                                :&nbsp;
                                            </span>
                                            <span className="font-weight-bold">
                                                {formatCurrency(amount)}
                                            </span>
                                        </>
                                    }
                                </div>
                            )
                        }
                        if (paymentMethod.id === "UAB_PAYROLL_DEDUCTION") {
                            return (
                                <div className="clearfix" key={paymentMethod.id}>
                                    <div className='d-flex align-items-center mb-3'>
                                        <span className='label' style={{ textTransform: 'none' }}>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, paymentMethod.name, "lbl_check_creditcard")
                                                :
                                                paymentMethod.name
                                            }
                                            :&nbsp;
                                        </span>
                                        <span className="font-weight-bold">
                                            {formatCurrency(amount)}
                                        </span>
                                    </div>
                                    <div className='col-md-6 px-0 mb-3'>
                                        <FormGroup>
                                            <Label>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Max Deductions", "lbl_check_maxdeductions")
                                                    :
                                                    "Max Deductions"
                                                }
                                            </Label>
                                            {payrollDeduction.PayrollDeduction > 1 ?
                                                <Input type='select' required onChange={(e) => dispatch(rootAction.checkoutActions.setPayrollMaxDeductions(e.target.value))}>
                                                    <option value={0}>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Select", "lbl_check_select")
                                                            :
                                                            "Select"
                                                        }
                                                    </option>
                                                    {[...Array(payrollDeduction.PayrollDeduction).keys()].map((index) => {
                                                        return (
                                                            <option key={index} value={index + 1}>{index + 1}</option>
                                                        )
                                                    })}
                                                </Input>
                                                :
                                                <Input type='select' required disabled>
                                                    <option value={payrollDeduction.PayrollDeduction}>{payrollDeduction.PayrollDeduction}</option>
                                                </Input>
                                            }
                                        </FormGroup>
                                    </div>
                                </div>
                            )
                        }
                        if (paymentMethod.id === "ON_ACCOUNT") {
                            return (
                                <div className="clearfix" key={paymentMethod.id}>
                                    <div className='d-flex align-items-center mb-3'>
                                        <span className='font-weight-normal' style={{ textTransform: 'none', fontSize: '1rem', letterSpacing: 0 }}>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, paymentMethod.name, "lbl_check_creditcard")
                                                :
                                                paymentMethod.name
                                            }
                                            {!isToggled?<>
                                            :&nbsp;</>:<></>
                                            }
                                        </span>
                                        {!isToggled&&
                                        <span className="font-weight-bold">
                                            {formatCurrency(amount)}
                                        </span>
                                        }
                                    </div>
                                </div>
                            )
                        }
                        return <React.Fragment key={paymentMethod.id}>
                        </React.Fragment>;
                    })
                })()
                :
                <ContentLoader
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    viewBox="0 0 900 150"
                    width='100%'
                    height={200}
                >
                    <rect x="0" y="0" width="100%" height="100%" />
                </ContentLoader>
            }
        </>
    )
}