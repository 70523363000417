import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Slider from 'react-slick';
import Sprites from '../../../assets/images/sprites.svg';
import { SiteLeftSidebarFilter } from "../../components/shared/SiteLeftSidebarFilter";

function MobileFilterButton({ refinementType, item, activeRefinements, onClick }) {
    return (
        <Button onClick={(e) => onClick(e, item)} className={`${activeRefinements && activeRefinements.length > 0 ? 'active' : ''}`} aria-label={`${item?.label}`} color='primary' outline>
            <span>{item?.label}</span>
            {Object.keys(activeRefinements || {}).length > 0 ?
                <>
                <div className='selected-filters'>
                {activeRefinements.map((item, idx) => {
                    return (
                        <b key={idx}>{item}</b>
                    );
                })}
                </div>
                </>
            : null }
        </Button>
    );
}

export const SiteMobileFilter = ({ refinements, totalRecords, windowInnerWidth }) => {
    const { availableRefinements } = useSelector(state => state.plpReducer);
    const [activeSlide, setActiveSlide] = useState(0);
    const [activeFilter, setActiveFilter] = useState('');
    const [isOpenMobile, setIsOpenMobile] = useState(false);

    const loopMobileFilters = (bool) => {
        Object.keys(availableRefinements).filter(
            (refinement) => {
                return availableRefinements[refinement].options?.length > 1 || (availableRefinements[refinement].options?.length === 1 && availableRefinements[refinement].options[0].hit_count !== totalRecords) || (availableRefinements[refinement].options?.length === 1 && refinements?.[refinement]?.length > 0)
            }
        ).forEach((attribute) => {
            {bool ?
                document.getElementById('refinement-' + attribute).classList.add('d-flex')
                :
                setTimeout(() => {
                    document.getElementById('refinement-' + attribute).classList.remove('d-flex')
                }, 425);
            }
        });
    }

    const openMobileFilters = (e, item) => {
        setIsOpenMobile(true);
        setActiveFilter(item);
        document.body.classList.add('menu-open');

        if (item.attribute === 'all_filters') {
            document.getElementById('refinements-wrapper').classList.add('all-filters');
            loopMobileFilters(true);
        } else {
            document.getElementById('refinement-' + item.attribute).classList.add('d-flex');
        }
    }

    const closeMobileFilters = (e) => {
        setIsOpenMobile(false);
        document.body.classList.remove('menu-open');
        document.getElementById('refinements-wrapper').classList.remove('all-filters');
        loopMobileFilters(false);
    }

    const options = {
        infinite: false,
        swipeToSlide: true,
        variableWidth: true,
        beforeChange: (current, next) => { setActiveSlide(next) }
    }

    useEffect(() => {
        const handleStickyFilter = () => {
            var header = document.getElementById('header');
            var mobileFiltersWrap = document.getElementById('mobile-filters-wrap');
            if (header.classList.contains('is-stuck')) {
                var headerHeight = header.offsetHeight;
                header.classList.add("shadow-none");
                mobileFiltersWrap.classList.add("is-sticky");
                mobileFiltersWrap.style.paddingTop = headerHeight + 3 + 'px';
            } else {
                header.classList.remove("shadow-none");
                mobileFiltersWrap.classList.remove("is-sticky");
                mobileFiltersWrap.style.paddingTop = 0;
            }
        };
        window.addEventListener('scroll', handleStickyFilter);
        window.addEventListener('resize', handleStickyFilter);
        return () => {
          window.removeEventListener('scroll', handleStickyFilter);
          window.removeEventListener('resize', handleStickyFilter);
        };
      }, []);

    return (
        <>
            <div id="mobile-filters-wrap" className='d-md-none d-flex w-100'>
                <button onClick={(e) => openMobileFilters(e, {attribute: 'all_filters', label: 'All Filters'})} type="button" className="btn btn-icon--action btn-filters-mobile" aria-label="All Filters">
                    <svg className="icon icon-filter">
                        <use href={`${Sprites}#filter`}></use>
                    </svg>
                </button>
                <Slider className='filter-bar-mobile' {...options}>
                    {Object.keys(availableRefinements).filter(
                        (refinement) => {
                            return availableRefinements[refinement].options?.length > 1 || (availableRefinements[refinement].options?.length === 1 && availableRefinements[refinement].options[0].hit_count !== totalRecords) || (availableRefinements[refinement].options?.length === 1 && refinements?.[refinement]?.length > 0)
                        }
                    ).map((item, idx) => (
                        <MobileFilterButton key={idx} refinementType={item} item={availableRefinements[item]} activeRefinements={refinements[item]} onClick={openMobileFilters} />
                    ))}
                </Slider>
            </div>
            <div className={`${isOpenMobile ? 'refinements-mobile show' : 'refinements-mobile'}`}>
                <div className='refinements-header'>
                    <Button onClick={closeMobileFilters} className='close' color="link" aria-label='Close'>
                        <span className="icon icon-arrowLeft"></span>
                    </Button>
                    <h2 className='filter'>{activeFilter?.label}</h2>
                </div>
                <SiteLeftSidebarFilter totalRecords={totalRecords} windowInnerWidth={windowInnerWidth} />
                <div className='refinements-footer'>
                    <Button onClick={closeMobileFilters} className='apply' color='primary' block>
                        Apply
                    </Button>
                </div>
            </div>
        </>
    );

}
