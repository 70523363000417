import { useEffect, useMemo, useState } from "react";
import rootAction from "../../../stateManagment/actions/rootAction";
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import debounce from 'lodash.debounce';
import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import { blur } from "../../../utils/blur";
import { replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import { useSelector } from "react-redux";

export const QuantityManipulator = ({ productDetail, initialProductActivity, colorInventory, initialQuantity = null, initialLineItemId = null }) => {
    const LocalizationLabelsArray = [];
    const [quantityRange, setQuantityRange] = useState({ min: 1, max: 300 });
    const productActivity = useSelector(state => state.pdpReducer);
    const dispatch = useAppDispatch();
    const currentCart = useSelector((state) => state.cartReducer);

    useEffect(() => {
        var range = getQtyRange();
        setQuantityRange(range);
        dispatch(rootAction.pdpActions.setQuantity({ quantity: initialQuantity || productActivity?.quantity, maxQuantity: range.max, minQuantity: range.min }));
    }, [currentCart]);

    const getQtyRange = () => {
        const style = productDetail.id + 
            (initialProductActivity.selectedColorId ? '-' + initialProductActivity.selectedColorId : '') +
            (initialProductActivity.selectedSizeId ? '-' + initialProductActivity.selectedSizeId : '');
            
        const inv = colorInventory?.find(c => 
            c.colorId === (initialProductActivity.selectedColorId ? initialProductActivity.selectedColorId : '') && 
            c.sizeId === (initialProductActivity.selectedSizeId ? initialProductActivity.selectedSizeId : ''));

        let maxQuantity = inv ? inv.ats : productDetail?.maxQuantity || 300;
        const minQuantity = productDetail?.minQuantity || 1;

        let sumOfMatchingSkus = currentCart?.products
            .filter(f => f.productId === style && 
                (!initialLineItemId || initialLineItemId !== f.lineItemId))
            .map(m => m.quantity)
            .reduce((acc, curr) => acc + curr, 0);
        maxQuantity -= sumOfMatchingSkus;

        return { min: minQuantity, max: maxQuantity };
    };

    const handleDecreaseQty = () => {
        dispatch(rootAction.pdpActions.decreaseQuantity(quantityRange.min));
    }

    const handleIncreaseQty = () => {
        dispatch(rootAction.pdpActions.increaseQuantity(quantityRange.max));
    }

    const handleChangQty = (e) => {
        const quantity = parseInt(e.target.value) || "";
        let qtyRange = getQtyRange();
        dispatch(rootAction.pdpActions.setQuantity({ quantity, maxQuantity: qtyRange.max, minQuantity: qtyRange.min }));
    }
    const debouncedChangeHandler = useMemo(
        () => debounce(handleChangQty, 1000)
        , []);

    return (
        <>
            <InputGroup className="custom-quantity">
                <InputGroupAddon addonType="prepend">
                    <Button
                        className="quantity-decrease"
                        disabled={Number(productActivity?.quantity) === quantityRange.min}
                        onClick={(e) => blur(e, handleDecreaseQty)}>
                    </Button>
                </InputGroupAddon>
                <Input
                    min={quantityRange.min}
                    max={quantityRange.max}
                    maxLength={3}
                    value={productActivity?.quantity}
                    className="text-center"
                    onChange={(e) => {
                        dispatch(rootAction.pdpActions.setOptimisticQuantity(e.target.value));
                        debouncedChangeHandler(e);
                    }} />
                <InputGroupAddon addonType="append">
                    <Button
                        className="quantity-increase"
                        disabled={Number(productActivity?.quantity) === quantityRange.max}
                        onClick={(e) => blur(e, handleIncreaseQty)}>
                    </Button>
                </InputGroupAddon>
            </InputGroup>
            {productActivity?.quantityWarning && quantityRange.max > 0 && <div className='invalid-feedback'>
                {LocalizationLabelsArray ?
                    replaceLoclizationLabel(LocalizationLabelsArray, `The maximum available quantity is ${quantityRange.max}. Please update your quantity`, "lbl_prd_det_invalidquantity")
                    :
                    `The maximum available quantity is ${quantityRange.max}. Please update your quantity`
                }
            </div>}
        </>
    )
}