import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { useNavigate } from 'react-router-dom';
import { useGetContentAssetQuery } from '../../../services/content';

const options = {
    slidesToShow: 1,
    infinite: false,
    arrows: false,
    dots: true,
    animateOut: "slideOutDown",
    animateIn: "slideInDown",
    smartSpeed: 750,
    autoplay: true,
    autoplayHoverPause: true,
    navText: [
        "<i className='fas fa-arrow-left'></i>",
        "<i className='fas fa-arrow-right'></i>"
    ]
}

const BannerSlider = () => {
    const homeBanner = useSelector((state) => state.commonReducer.homeBanner);
    const {data: homeBannerHtml, isFetching: isFetchingHomeBannerHtml} = useGetContentAssetQuery('splash-html');
    const navigate = useNavigate();
    const navigateOnClickBanner = (e) => {
        e.preventDefault();
        let href = e.target.href.replace(e.target.baseURI, '')

        if (e.target.tagName.toLowerCase() === 'a') {
            navigate(href);
        }
    };

    if (homeBanner.isLoading || isFetchingHomeBannerHtml) {
        return <ContentLoader width={800} height={600} className='w-100'>
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
    }

    return (
        <>
            {homeBanner.slides?.length > 1 ?
                <Slider
                    className="home-slides-three text-center"
                    {...options}
                >
                    {homeBanner.slides?.map((item, idx) =>
                        <img key={idx} src={item.bannerImgUrl} alt="" className="img-fluid" />
                    )}
                </Slider> :
                <div className="text-center">
                    {homeBanner?.slides?.[0]?.bannerImgUrl ?
                        <img src={homeBanner?.slides?.[0]?.bannerImgUrl} alt="" className="img-fluid w-100" />
                        :
                        <div className="home-banner-html" onClick={navigateOnClickBanner} dangerouslySetInnerHTML={{ __html: homeBannerHtml?.ContentValue }}></div>
                    }
                </div>
            }
        </>
    );
}

export default BannerSlider;
