import ContentPage from '../../components/layout/ContentPage';
import BannerSlider from '../../components/home/BannerSlider'
import Seo from '../../components/shared/Seo';
import { useGetHomeBannerQuery } from '../../../services/content';

const Home = () => {
    useGetHomeBannerQuery();

    const handleBannerButtonClickUrl = (url) => {
        if (url != undefined && url != null && url.length > 0) {
            window.location.href = url;
        } else {
            return false;
        }
    }

    return (
        <ContentPage title={"Home"} contentId={"homepage"} contentFull={false}>
            <Seo title="Home" description="Home" keywords="Home" />

            <BannerSlider handleBannerButtonClickUrl={handleBannerButtonClickUrl} />
        </ContentPage>
    );
}

export default Home;
