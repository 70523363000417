import { Fragment, useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import Seo from '../../components/shared/Seo';
import { getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import { useGetOrderDetailsQuery } from '../../../services/order';
import { formatPhoneNumber } from 'react-phone-number-input';
import { formatCurrency } from '../../../helpers/ConversionHelper';
import { ORDER_STATUS, PAYMENT_INSTRUMENTS } from '../../../helpers/Constants';
import { orderDetailExample } from '../../../tests/orderDetailExample';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import rootAction from '../../../stateManagment/actions/rootAction';
import { NUMBER_BINARY_OPERATORS } from '@babel/types';
import { useSelector } from 'react-redux';
import VASTemplateV2 from '../../components/products/VASTemplateV2';
import NotFound from '../common/NotFound';
import OrderDetailsLoader from '../../components/skeletons/OrderDetailsLoader';
import _ from 'lodash';
import { ToggleContext } from '../../../ToggleProvider';

const OrderDetail = () => {
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const dispatch = useAppDispatch();
    //--set product id and cat from url
    const { order_number: orderId } = useParams();
    const LocalizationLabelsArray = [];
    const [toggle, setToggle] = useState({})
    const { data: order, isFetching: isOrderDetailFetching, error: orderDetailFetchError } = useGetOrderDetailsQuery(orderId);
    // const order = orderDetailExample;
    const [billingAddress, setBillingAddress] = useState({});
    const [shippingAddress, setShippingAddress] = useState({});
    const [paymentInstruments, setPaymentInstruments] = useState([]);
    const [shipments, setShipments] = useState([]);
    const trackingLink = useSelector(state => state.commonReducer.trackingLink);

    useEffect(() => {
        if (!isOrderDetailFetching && order.OrderDetails.OrderNumber !== 0) {
            setBillingAddress(order.OrderDetails.BillingInfo);
            setShippingAddress(order.OrderDetails.ShippingInfo);
            setPaymentInstruments(order.paymentInstruments);
            //group order line items by shipments, there can be multiple shipment in a line item
            let shippingGroups = order.OrderDetails.OrderLineItems.reduce((acc, lineItem, index) => {
                const lineItemQuantity = lineItem.Quantity;
                const shippedQuantity = lineItem.ShipmentInfo
                    .reduce((sum, s) => sum + s.Quantity, 0);
                const notShippedQuantity = lineItemQuantity - shippedQuantity;
                if (notShippedQuantity > 0) {
                    const notShippedKey = 'not shipped';
                    if (!acc[notShippedKey]) {
                        acc[notShippedKey] = [];
                    }
                    acc[notShippedKey].push({ id: index, quantity: notShippedQuantity });
                }

                lineItem.ShipmentInfo.forEach(shipment => {
                    const { ShipmentNumber, Quantity } = shipment;

                    const shipmentKey = `shipment-${ShipmentNumber}`;
                    if (!acc[shipmentKey]) {
                        acc[shipmentKey] = [];
                    }
                    var trackingUrl = '';
                    if (trackingLink && shipment.ShipCarrier && shipment.TrackingNumber) {
                        var shipCarrier = shipment.ShipCarrier.split('(');
                        var carrierId = shipCarrier.length > 0 ? shipCarrier[0].trim().toLowerCase() : '';
                        trackingUrl = trackingLink.replace('{{carrier}}', carrierId).replace('{{tracking_number}}', shipment.TrackingNumber);
                    }

                    acc[shipmentKey].push({ id: index, quantity: Quantity, trackingNumber: shipment.TrackingNumber, trackingUrl: trackingUrl });
                });

                return acc;
            }, {});

            shippingGroups = order.OrderDetails.OrderCancelledLineItems.reduce((acc, lineItem, index) => {
                const lineItemQuantity = lineItem.Quantity;
                const cancelledShipKey = 'cancelled';
                if (!acc[cancelledShipKey]) {
                    acc[cancelledShipKey] = [];
                }
                acc[cancelledShipKey].push({ id: index, quantity: lineItemQuantity });
                return acc;
            }, shippingGroups);

            setShipments(shippingGroups);
        }
    }, [isOrderDetailFetching]);

    if (!isOrderDetailFetching && (orderDetailFetchError || order.OrderDetails.OrderNumber === 0)) {
        return <NotFound />
    }

    if(isOrderDetailFetching){
        return <OrderDetailsLoader />;
    }

    return (
        <>
            <Seo title="Order Details" description="Order Details" keywords="Order Details" />
            <SiteBreadcrumb title="Order Detail" />
            <section className='order-details-page d-flex my-4 my-md-8' id='order-details'>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="order-details-title mb-0 mb-md-2">
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "Order Details", "lbl_order_details")
                                    :
                                    "Order Details"
                                }
                            </h1>
                        </div>
                        <div className="col-12 mt-5 mb-8">
                            <div className="row order-details-data">
                                <div className="col-12">
                                    <span className="order-number-label font-weight-bold mr-1">Order Number:</span>
                                    <span className="order-number">
                                        {orderId}
                                    </span>
                                </div>
                                <div className="col-12">
                                    <span className="order-date-label font-weight-bold mr-1">Order Date:</span>
                                    <span className="order-date">{new Date(order?.OrderDate).toLocaleDateString()}</span>
                                </div>
                                <div className="col-12">
                                    <span className="order-number-label font-weight-bold mr-1">Order Status:</span>
                                    <span className="order-status">{ORDER_STATUS[order?.OrderDetails.OrderStatus]}</span>
                                </div>

                            </div>
                        </div>

                        <div className={!isToggled?"col-12 col-md-8":"col-12 col-md-12"}>
                            <div className='row mb-5 mb-md-7'>
                                <div className='col-12'>
                                    <div className="cart-card">
                                        <div className="cart-card__header cart-card__header--large pb-4">
                                            Shipping &amp; Billing
                                        </div>
                                        <div className="cart-card__body container pb-0">
                                            <div className="row shipping-billing-summary">
                                                <div className="col-md-6">
                                                    <div className="row flex-column">
                                                        <div className="col-auto mb-6 mb-md-5">
                                                            <p className="label mb-0 mb-md-2">
                                                                Shipping Address
                                                            </p>
                                                            <div className="address-summary">
                                                                <div>
                                                                    <span className="firstName mr-1">
                                                                        {shippingAddress?.FirstName}
                                                                    </span>
                                                                    <span className="lastName">
                                                                        {shippingAddress?.LastName}
                                                                    </span>
                                                                </div>

                                                                <div className="companyName">
                                                                    {shippingAddress?.CompanyName}
                                                                </div>

                                                                <div className="address1">
                                                                    {shippingAddress?.AddressLine1}
                                                                </div>

                                                                <div className="address2">
                                                                    {shippingAddress?.AddressLine2}
                                                                </div>

                                                                <div className="addressLine3">
                                                                    <span className="city mr-1">
                                                                        {shippingAddress?.City},
                                                                    </span>
                                                                    <span className="stateCode mr-1">
                                                                        {shippingAddress?.State},
                                                                    </span>
                                                                    <span className="postalCode">
                                                                        {shippingAddress?.Zip}
                                                                    </span>
                                                                </div>

                                                                <span className="country">
                                                                    United States
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-auto mb-5">
                                                            <p className="label mb-0 mb-md-2">
                                                                Order contact information
                                                            </p>
                                                            <div>
                                                                <div className="order-summary-email">{order?.OrderDetails?.ContactEmail}</div>
                                                                <div className="order-summary-phone">{formatPhoneNumber(shippingAddress?.PhoneNumber)}</div>
                                                            </div>

                                                        </div>

                                                        <div className="col-auto mb-5 mb-md-0">
                                                            <p className="label mb-0 mb-md-2">
                                                                Shipping Method
                                                            </p>
                                                            {!isToggled?<>
                                                            <div>
                                                                <span className='mr-1'>
                                                                    {order?.OrderDetails.ShippingMethodDesc}:
                                                                </span>
                                                                <span className="font-weight-bold">
                                                                    {formatCurrency(order?.OrderDetails.shippingCharge)}
                                                                </span>
                                                            </div></>:<>
                                                            <span className='mr-1'>
                                                                    {order?.OrderDetails.ShippingMethodDesc}
                                                                </span>
                                                            </>}
                                                        </div>

                                                    </div>


                                                    <p className="summary-section-label shipping-addr-label multi-shipping">
                                                        Addresses &amp; shipping methods are listed under your items.
                                                    </p>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row flex-column">
                                                        <div className="col-auto mb-5 billing">
                                                            <p className="label mb-0 mb-md-2">
                                                                Billing Address
                                                            </p>
                                                            <div className="address-summary">
                                                                {(billingAddress?.FirstName?.trim() || billingAddress?.LastName?.trim()) &&
                                                                    <div>
                                                                        <span className="firstName mr-1">
                                                                            {billingAddress?.FirstName}
                                                                        </span>
                                                                        <span className="lastName">
                                                                            {billingAddress?.LastName}
                                                                        </span>
                                                                    </div>}

                                                                <div className="companyName">
                                                                    {billingAddress?.CompanyName}
                                                                </div>

                                                                <div className="address1">
                                                                    {billingAddress?.AddressLine1}
                                                                </div>

                                                                <div className="address2">
                                                                    {billingAddress?.AddressLine2}
                                                                </div>

                                                                <div className="addressLine3">
                                                                    <span className="city mr-1">
                                                                        {billingAddress?.City},
                                                                    </span>
                                                                    <span className="stateCode mr-1">
                                                                        {billingAddress?.State},
                                                                    </span>
                                                                    <span className="postalCode">
                                                                        {billingAddress?.Zip}
                                                                    </span>
                                                                </div>

                                                                <span className="country">

                                                                    United States

                                                                </span>
                                                            </div>
                                                        </div>


                                                        {paymentInstruments && paymentInstruments.length > 0 &&
                                                            <div className="col-auto">
                                                                <p className="label mb-0 mb-md-2">
                                                                    Payment Method
                                                                </p>
                                                                <div className="mb-2">
                                                                    <div className="giftcard-details">
                                                                    </div>
                                                                </div>
                                                                {paymentInstruments.map(pi => {
                                                                    return (
                                                                        <div className="payment-details" key={pi.PaymentMethodId}>
                                                                            <div className="credit-card-type">
                                                                                {!isToggled?<><span>{PAYMENT_INSTRUMENTS[pi.PaymentMethodId]}: </span>
                                                                                <span className="grand-total-sum font-weight-bold">
                                                                                    {formatCurrency(pi.Amount)}
                                                                                </span></>:<><span>{PAYMENT_INSTRUMENTS[pi.PaymentMethodId]}</span></>}
                                                                            </div>

                                                                            {pi.PaymentMethodId === 'UAB_PAYROLL_DEDUCTION' &&
                                                                                <div className="credit-card-number">
                                                                                    <span>Max Deductions: {pi.PDMaxDeductions}</span>
                                                                                </div>}

                                                                            {pi.PaymentMethodId === 'CREDIT_CARD' &&
                                                                                <div className="credit-card-number">
                                                                                    <span className="credit-card-expiration-date">
                                                                                        <span>**** **** **** {pi.Last4}</span>
                                                                                        <span> Exp {pi.ExpMonth}/{pi.ExpYear}</span>
                                                                                    </span>
                                                                                </div>}
                                                                        </div>
                                                                    )
                                                                })}

                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5 mb-md-7'>
                                {shipments &&
                                    Object.keys(shipments)?.map((ShipmentNumber) => {
                                        const { trackingNumber } = shipments[ShipmentNumber]?.[0];
                                        const totalItems = shipments[ShipmentNumber].reduce((acc, curr) => {
                                            return acc + curr.quantity;
                                        }, 0);
                                        return (
                                            <div className='col-12 mb-5' key={ShipmentNumber}>
                                                <h6 className="mb-2 mb-md-1">
                                                    {totalItems} Items
                                                    <div className="sorted-shipment-subtitle my-2">
                                                        {ShipmentNumber !== 'not shipped' && ShipmentNumber !== 'cancelled' ?
                                                            <>
                                                                Shipped&nbsp;(Tracking #: <a href={shipments[ShipmentNumber]?.[0].trackingUrl}>{trackingNumber}</a>)
                                                            </>
                                                            :
                                                            <>
                                                                {ShipmentNumber === 'cancelled' ? 'Cancelled' : 'Not Shipped'}
                                                            </>
                                                        }
                                                    </div>
                                                </h6>
                                                <div className='cart-card product-list-card'>
                                                    <div className="row cart-card__header hidden-sm-down font-weight-bold">
                                                        <div className="col-auto product pr-0">
                                                            <div className="product-header">Product</div>
                                                        </div>
                                                        <div className="col pl-0"></div>
                                                        <div className="col-6">
                                                            <div className="row">
                                                                {!isToggled&&<div className="col text-center">Price</div>}
                                                                <div className="col-5 text-center qty">Qty</div>
                                                                {!isToggled&&<div className="col total text-right">Total</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='cart-card__body py-0 container'>
                                                        {shipments[ShipmentNumber].map((shipment, index) => {
                                                            const lineItem = ShipmentNumber != 'cancelled' ? {
                                                                ...order.OrderDetails.OrderLineItems[shipment.id]
                                                            } : {
                                                                ...order.OrderDetails.OrderCancelledLineItems[shipment.id]
                                                            };
                                                            const sku = [lineItem.Style, lineItem.Color, lineItem.Size].filter(item => item).join('-');
                                                            const styleColor = [lineItem.Style, lineItem.Color].join('-');
                                                            const image = order.Images.find(image => image.startsWith(styleColor))?.split(',')?.[1];
                                                            const hexcode = (lineItem.Color && lineItem.Color.length > 0) ? order.HexCodes.find(hexcode => hexcode.startsWith(lineItem.Color + ","))?.split(',')?.[1] : "#ffffff";
                                                            const lineColorName = (lineItem.Color && lineItem.Color.length > 0) ? order.ColorNames.find(colname => colname.startsWith(lineItem.Color + ","))?.split(',')?.[1] : lineItem.Color;
                                                            const lineDepartment = order.Departments.find(deptname => deptname.startsWith(sku + ","))?.split(',')?.[1];
                                                            const key = sku + index + ShipmentNumber;
                                                            return (
                                                                <div className='product-line-item' key={key}>
                                                                    <div className='row product-line-item-details'>
                                                                        <div className="col-auto pr-0">
                                                                            <div className="item-image">
                                                                                <img className="product-image" src={image} alt="" />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col pl-0 lineitem-attributes-column'>
                                                                            <div className='item-attributes'>
                                                                                <div className="line-item-name">
                                                                                    {lineItem.ItemName}
                                                                                </div>
                                                                                <br />
                                                                                <div>
                                                                                    {lineItem.Color?.length > 0 && <span className="line-item-style">Style #: {lineItem.Style}</span>}<br />
                                                                                    {lineItem.Color?.length > 0 && <span className="line-item-style">Color: {lineColorName ? lineColorName : lineItem.Color}</span>}<br />
                                                                                    {lineItem.Size?.length > 0 && <span className="line-item-style">Size: {lineItem.Size}</span>}<br /><br />
                                                                                    {!isToggled &&
                                                                                    <Link className='link-underline' to={`/${getLanguageCodeFromSession()}/product-detail/${sku}/${lineDepartment}/${_.kebabCase(lineItem.ItemName)}`} >
                                                                                        Buy It Again
                                                                                    </Link>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12 col-md-6'>
                                                                            <div className="col-12 d-flex justify-content-end p-0">
                                                                                <div className="col-8 col-md-12 p-0">
                                                                                    <div className="row">
                                                                                        {!isToggled&&<Fragment>
                                                                                            <div className="col-4 d-md-none">
                                                                                                <span className="line-item-price-info">Price</span>
                                                                                            </div>
                                                                                            <div className="col-8 col-md text-md-center text-right">
                                                                                                {lineItem.DiscountedPrice > lineItem.Price * lineItem.Quantity ? <span className="price"><strong>{formatCurrency(lineItem.DiscountedPrice / lineItem.Quantity)}/ea</strong></span>
                                                                                                    : lineItem.DiscountedPrice < lineItem.Price * lineItem.Quantity ? <Fragment><span className="price"><strong className='text-danger'>{formatCurrency(lineItem.DiscountedPrice / lineItem.Quantity)}/ea</strong></span >&nbsp;<span style={{ textDecoration: "line-through" }} className="price"><strong>{formatCurrency(lineItem.Price)}</strong></span></Fragment>
                                                                                                        : <span className="price"><strong>{formatCurrency(lineItem.DiscountedPrice / lineItem.Quantity)}/ea</strong></span>}
                                                                                            </div>
                                                                                            </Fragment>
                                                                                        }
                                                                                        <div className="col-4 d-md-none">
                                                                                            <span className="line-item-price-info">Qty</span>
                                                                                        </div>
                                                                                        <div className="col-8 col-md-5 text-md-center text-right">
                                                                                            <span className="qty-card-quantity-count"><strong>{lineItem.Quantity}</strong></span>
                                                                                        </div>
                                                                                        {!isToggled&&<Fragment>
                                                                                            <div className="col-4 d-md-none">
                                                                                                <span className="line-item-price-info">Total</span>
                                                                                            </div>
                                                                                            <div className="col-8 col-md text-right">
                                                                                                <span className="price"><strong>{formatCurrency(lineItem.DiscountedPrice)}</strong></span>
                                                                                            </div>
                                                                                            </Fragment>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    {lineItem?.VASSalesLineComponents?.length > 0 &&

                                                                        (() => {
                                                                            const vasPrice = lineItem.VASSalesLineComponents?.reduce((total, vas) => {
                                                                                return total + vas.Price
                                                                            }, 0) || 0;
                                                                            const vasTotal = vasPrice * lineItem.Quantity;
                                                                            return (
                                                                                <>
                                                                                    <div className='row mb-md-4 mt-4'>
                                                                                        <div className='col pr-3 embroidery-lineitem-summary orderdetails-summary'>
                                                                                            <div className='row'>
                                                                                                <div className="col-auto pr-0">
                                                                                                    <div className="item-image"></div>
                                                                                                </div>
                                                                                                <div className='col pl-0'>
                                                                                                    <div className='align-items-center card-link d-flex font-weight-bold font-size-p875 text-uppercase w-100'>
                                                                                                        <span className="d-md-inline-block d-none icon icon-embroideryNeedle mr-1"></span>
                                                                                                        <div className='d-md-none'>Embroidery</div>
                                                                                                        <div className="align-items-center d-none d-md-flex tooltip-box">
                                                                                                            Embroidery added
                                                                                                            <span className="tooltip-icon ml-1"></span>
                                                                                                            <div className="fade tooltip">
                                                                                                                <div className="arrow"></div>
                                                                                                                <div className="tooltip-inner">Embroidery details listed below.</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-auto col-md-6 pr-0 pr-md-4'>
                                                                                                    <div className='row'>
                                                                                                        {!isToggled&&<div className='col text-center embroidery-cart-price hidden-sm-down'><strong>{formatCurrency(vasPrice)}</strong></div>}
                                                                                                        <div className="col-5 hidden-sm-down"></div>
                                                                                                        {!isToggled&&<div className="col embroidery-cart-price text-right"><strong>{formatCurrency(vasTotal)}</strong></div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row mb-4">
                                                                                        <div className="col-auto pr-0">
                                                                                            <div className="item-image"></div>
                                                                                        </div>
                                                                                        <div className="col pl-0">
                                                                                            <span
                                                                                                onClick={() => {
                                                                                                    setToggle({
                                                                                                        ...toggle,
                                                                                                        [key]: !toggle[key]
                                                                                                    })
                                                                                                }}
                                                                                                className={`embroidery_details ${!toggle[key] ? "active" : ""}`}>
                                                                                                <span className='expand_div'>
                                                                                                    <span>Show Embroidery Details</span>
                                                                                                </span>
                                                                                                <span className='collapse_div'>
                                                                                                    <span>Hide Embroidery Details</span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col col-md-6 pl-0 hidden-sm-down"></div>
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-auto pr-0">
                                                                                            <div className="item-image"></div>
                                                                                        </div>
                                                                                        <div className="col pl-0 EmbSelection">
                                                                                            {toggle[key] && lineItem?.EmbroideryConfiguration && (
                                                                                                <VASTemplateV2
                                                                                                    vasData={lineItem.EmbroideryConfiguration}
                                                                                                    VasSelectionIndex={0}
                                                                                                    ItemChecked={false}
                                                                                                    PreviewBaseUrl={''}
                                                                                                    hexCode={hexcode}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })()
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            <div className="row d-none d-md-flex">
                                <div className="col-md-4 mb-5">
                                    <Link to={`/${getLanguageCodeFromSession()}/orders-history`} className="btn btn-primary w-100" id="lbl_ordrhis_backtoorderhistory">
                                        {replaceLoclizationLabel(LocalizationLabelsArray, "Back to Order History", "lbl_ordrhis_backtoorderhistory")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {!isToggled&&(() => {
                            const subTotal = order?.OrderDetails.OrderLineItems?.reduce((total, lineItem) => {
                                const vasTotal = lineItem.VASSalesLineComponents?.reduce((total, vas) => {
                                    return total + (vas.Price * lineItem.Quantity)
                                }, 0) || 0;
                                return total + (lineItem.DiscountedPrice) + vasTotal;
                            }, 0);
                            const itemTotal = subTotal + order?.OrderDetails.OrderDiscountAmountAtOrderPlacement;
                            return (
                                <div className='col-12 col-md-4'>
                                    <div className="sidebar-card checkout-order-summary mb-5">
                                        <div className="card-header-custom">
                                            Order Summary
                                        </div>
                                        <div className="sidebar-card__body order-total-summary">
                                            <div className="order-summary-costs-list">

                                                <div className="row total-item">
                                                    <div className="col-8">
                                                        <p className="order-receipt-label"><span>Item Total</span></p>
                                                    </div>
                                                    <div className="col-4">
                                                        <p className="text-right"><span className="order-total">{formatCurrency(itemTotal)}</span></p>
                                                    </div>
                                                </div>
                                                {order?.OrderDetails.OrderDiscountAmountAtOrderPlacement > 0 &&
                                                    <div className="row order-discount">
                                                        <div className="col-8">
                                                            <p className="order-receipt-label"><span>Discounts</span></p>
                                                        </div>
                                                        <div className="col-4">
                                                            <p className="text-right"><span className="order-discount-total">-{formatCurrency(order?.OrderDetails.OrderDiscountAmountAtOrderPlacement)}</span></p>
                                                        </div>
                                                    </div>}
                                                <div className="row subtotal-item ">
                                                    <div className="col-8">
                                                        <p className="order-receipt-label"><span>Item Subtotal</span></p>
                                                    </div>
                                                    <div className="col-4">
                                                        <p className="text-right"><span className="sub-total">{formatCurrency(subTotal)}</span></p>
                                                    </div>
                                                </div>
                                                <div className="row shipping-item">
                                                    <div className="col-8">
                                                        <p className="order-receipt-label"><span>Shipping Cost</span></p>
                                                    </div>
                                                    <div className="col-4">
                                                        <p className="text-right"><span className="shipping-total-cost">{formatCurrency(order?.OrderDetails.shippingChargeAtOrderPlacement)}</span></p>
                                                    </div>
                                                </div>
                                                {/* <div className="row digitize-total-item ">
                                            <div className="col-8">
                                                <p>Digitizing Setup Fee</p>
                                            </div>
                                            <div className="col-4">
                                                <p className="text-right digitize-cost">$69.00</p>
                                            </div>
                                        </div> */}
                                                {/* <div className="row shipping-discount hide-shipping-discount">
                                            <div className="col-8">
                                                <p className="order-receipt-label"><span>Shipping Discount</span></p>
                                            </div>
                                            <div className="col-4">
                                                <p className="text-right"><span className="shipping-discount-total">- $0.00</span></p>
                                            </div>
                                        </div> */}
                                                <div className="row sales-tax-item">
                                                    <div className="col-8">
                                                        <p className="order-receipt-label"><span>Estimated Sales Tax</span></p>
                                                    </div>
                                                    <div className="col-4">
                                                        <p className="text-right"><span className="tax-total">{formatCurrency(order?.OrderDetails.TaxChargeAtOrderPlacement)}</span></p>
                                                    </div>
                                                </div>
                                                {/* <div className="row gift-card-total-item d-none">
                                            <div className="col-8">
                                                <p className="order-receipt-label"><span>Gift Cards Applied</span></p>
                                            </div>
                                            <div className="col-4">
                                                <p className="text-right"><span className="giftcard-total">$0.00</span></p>
                                            </div>
                                        </div> */}
                                                {/* <div className="row retail-delivery-fee d-none">
                                            <div className="col-8">
                                                <p>Retail Delivery Fee</p>
                                            </div>
                                            <div className="col-4">
                                                <p className="text-right"><span className="retail-fee">{formatCurrency(order?.OrderDetails.ShippingChargeAtOrderPlacement)}</span></p>
                                            </div>
                                        </div> */}
                                            </div>
                                            <div className="row no-gutters estimate-total">
                                                <div className="col-9">
                                                    <div className="order-receipt-label m-0"><span>Estimated Billed Amount</span></div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="text-right grand-total m-0"><span className="grand-total-sum">{formatCurrency(order?.OrderDetails.OrderTotalAmountAtOrderPlacement)}</span></div>
                                                </div>
                                            </div>
                                            <div className="row order-savings justify-content-center ">
                                                <span className="mr-1">Your savings:</span>
                                                <span className="total-savings">{formatCurrency(order?.OrderDetails.OrderDiscountAmountAtOrderPlacement)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-md-none">
                                        <div className="col-12 mb-5">
                                            <Link to={`/${getLanguageCodeFromSession()}/orders-history`} className="btn btn-primary w-100" id="lbl_ordrhis_backtoorderhistory">
                                                {replaceLoclizationLabel(LocalizationLabelsArray, "Back to Order History", "lbl_ordrhis_backtoorderhistory")}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })()}
                    </div>
                </div>
            </section>

        </>
    );
}

export default OrderDetail;
