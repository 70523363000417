import { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { api } from "../../../services/api";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  // const isSomeQueryPending = useSelector(state => Object.values(state[api.reducerPath].queries).some(query => query.status === 'pending'))

  useEffect(() => {
    // console.log(isSomeQueryPending);
    // if (!isSomeQueryPending) {
    // console.log('scrolling to top');
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    // }
  }, [pathname]);

  return null;
}
