import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetCartDataQuery } from '../../../services/cart.ts';

const SizingBoard = () => {
    useGetCartDataQuery();
    const currentCart = useSelector((state) => state.cartReducer);
    const [displayData, setDisplayData] = useState([]);
    const { user: { SizingTemplate: userSizingTemplate } } = useSelector((state) => state.userReducer);

    useEffect(() => {
        // Add classes not there in cart
        const groupedByCategory = currentCart?.products?.reduce((accumulator, item) => {
            const { itemClass, quantity } = item;

            if (!accumulator[itemClass]) {
                accumulator[itemClass] = { items: [], total: 0, allowed: 0 };
            }

            accumulator[itemClass].items.push(item);
            accumulator[itemClass].total += quantity;
            try {
                accumulator[itemClass].allowed = userSizingTemplate?.SizingTemplates[0]?.Quotas?.find(quota => quota.MerchClass == itemClass)?.MaxQuantityPerOrder || 0;
            } catch (error) {
                accumulator[itemClass].allowed = 0;
            }

            return accumulator;

        }, {});

        let updatedData = (groupedByCategory && Object.keys(groupedByCategory).length > 0) ? groupedByCategory : {};
        try {
            for (let i = 0; i < userSizingTemplate?.SizingTemplates[0]?.Quotas?.length; i++) {
                if (!Object.keys(updatedData).includes(userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClass)) {
                    updatedData[userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClass] = { items: [], total: 0, allowed: userSizingTemplate.SizingTemplates[0]?.Quotas[i].MaxQuantityPerOrder };
                }
            }
            setDisplayData(Object.entries(updatedData));
        } catch (error) {

        }

    }, [currentCart]);

    const getDisplayData = (dd) => {
        return Object.entries(dd).map(([category, data]) => (
            <tr>
                <td style={{ width: "auto", padding: "8px", backgroundColor: "#a6a6a6", border: "1px solid black" }} >{category}</td>
                <td style={{ width: "auto", padding: "8px", border: "1px solid black" }} className='text-center'>{data.total}</td>
                <td style={{ width: "auto", padding: "8px", border: "1px solid black" }} className='text-center'>{data.allowed}</td>
                <td style={{ width: "auto", padding: "8px", border: "1px solid black" }} className='text-center'>{data.allowed - data.total}</td>
            </tr>
        ));
    }

    return (
        <div id="sizingBoardContainer" className='container'>
            <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid black" }}>
                <thead>
                    <tr>
                        <th style={{ width: "auto", backgroundColor: "#404040", padding: "8px", border: "1px solid black", color: "#efefef", fontSize :"13px" }}>Category</th>
                        <th style={{ width: "auto", backgroundColor: "#404040", padding: "8px", border: "1px solid black", color: "#efefef", fontSize :"13px" }} className='text-center'>In Cart</th>
                        <th style={{ width: "auto", backgroundColor: "#404040", padding: "8px", border: "1px solid black", color: "#efefef", fontSize :"13px"  }} className='text-center'>Credits</th>
                        <th style={{ width: "auto", backgroundColor: "#404040", padding: "8px", border: "1px solid black", color: "#efefef", fontSize :"13px"  }} className='text-center'>Remaining</th>
                    </tr>
                </thead>
                <tbody>
                    {displayData?.map(([category, data]) => (
                    <tr>
                        <td style={{width: "auto" , padding: "8px", backgroundColor: "#a6a6a6" , border: "1px solid black", color: "#000000", fontSize :"13px"}} ><b>{category}</b></td>
                        <td style={{width: "auto" , padding: "8px", border: "1px solid black", fontSize :"13px"}} className='text-center'>{data.total}</td>
                        <td style={{width: "auto" , padding: "8px", border: "1px solid black", fontSize :"13px"}} className='text-center'>{data.allowed}</td>
                        <td style={{width: "auto" , padding: "8px", border: "1px solid black", fontSize :"13px"}} className='text-center'>{data.allowed-data.total}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default SizingBoard;