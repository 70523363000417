import { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { useSelector } from 'react-redux';
import rootAction from '../../../stateManagment/actions/rootAction';
import Config from '../../../helpers/Config.js';

import { getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';

import Seo from '../../components/shared/Seo';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import { useGetCartDataQuery } from '../../../services/cart';
import { useGetAddressesQuery, useMeQuery } from '../../../services/user';
import { useConfirmOrderMutation, useGetPaymentMethodsQuery, useTokenizeCardMutation, useGetShippingMethodsQuery } from '../../../services/checkout';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { AddressEditModal } from './AddressEditModal';
import { AddressSummary } from './AddressSummary';
import { OrderSummary } from './OrderSummary';
import { ShippingBagSummary } from './ShippingBagSummary';
import { useSearchParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useCalculateShippingQuery } from "../../../services/cart";
import { ToggleContext } from '../../../ToggleProvider';
import ContentLoader from 'react-content-loader';

const isAddressValid = (address) => {
    address = address || {};
    return address && address.id && address.firstName && address.lastName && address.address1 && address.city && address.stateCode && address.postalCode && address.countryCode;
}

const Checkout = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const stripeRef = useRef();
    const LocalizationLabelsArray = [];

    const [searchParams, setSearchParams] = useSearchParams();
    const [errorMessage, setErrorMessage] = useState(searchParams.get('WebError') || '');

    // State Selectors
    const cart = useSelector((state) => state.cartReducer);
    const siteName = useSelector(state => state.commonReducer.siteName);
    const checkoutActivity = useSelector((state) => state.checkout);
    const { siteDefaultLocation: defaultBillingAddress, shipToLocationEnabled, siteLocations, isPayrollDeductionEnabled, shippingAddressEditable, billingAddressEditable } = useSelector((state) => state.commonReducer);
    const { user: { CreditBalance: creditBalance, PayrollDeduction: payrollDeduction, EmployeeLocations: employeeLocations, EmployeeLocation: employeeLocation, CreditEnabled: creditEnabled, UserID: userId, IsOnAccount: isOnAccount } } = useSelector((state) => state.userReducer);
    const loginUser = useSelector(state => state.userReducer.user);
    const { shippingAddress, billingAddress, shippingMethod, editableBilling } = useSelector((state) => state.checkout);
    const [hasCreditCardPaymentMethod, setHasCreditCardPaymentMethod] = useState(false);
    const { isToggled, setIsToggled } = useContext(ToggleContext);

    // Queries
    const { isFetching: isMeFetching } = useMeQuery(null, {
        refetchOnMountOrArgChange: true,
    }); // It's a me.... Mario!
    const { isFetching: isCartFetching, data: cartDataFetched } = useGetCartDataQuery(null);
    const { data: customerAddresses, isFetching: isCustomerAddressesFetching, isError: isAddressError } = useGetAddressesQuery({
        cartProducts: []
    }, {
        refetchOnMountOrArgChange: true,
    });
    const { data: paymentMethods, isFetching: isPaymentMethodFetching } = useGetPaymentMethodsQuery(null, {
        refetchOnMountOrArgChange: true,
    });
    const [summaries, setSummaries] = useState(['shipping', 'billing']);

    const { data: shippingMethods, isLoading: isShippingMethodsLoading, isFetching: isShippingMethodsFetching } = useGetShippingMethodsQuery(null, {
        refetchOnMountOrArgChange: true
    });

    const { isFetching: isCalculatFetching } = useCalculateShippingQuery({
        billingAddress,
        shippingAddress,
        shippingId: shippingMethod?.id || shippingMethods?.default_shipping_method_id
    }, {
        skip: isShippingMethodsFetching,
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        if (!isPaymentMethodFetching && paymentMethods && loginUser?.UserID) {
            const onAccountPaymentMethod = paymentMethods.applicable_payment_methods?.find(pm => pm.id === 'ON_ACCOUNT');
            const creditCardPaymentMethod = paymentMethods.applicable_payment_methods?.find(pm => pm.id === 'CREDIT_CARD');
            setHasCreditCardPaymentMethod(creditCardPaymentMethod && (!onAccountPaymentMethod || !loginUser?.IsOnAccount));
            if(!isToggled){
                setSummaries(loginUser?.IsOnAccount && onAccountPaymentMethod ? ['shipping', 'po', 'billing'] : summaries);
            }else{
                setSummaries(['shipping', 'billing']);
            }
        }
    }, [isPaymentMethodFetching, paymentMethods, loginUser]);

    // Mutations
    const [confirmOrder, { isLoading: isOrderLoading, isFetching: isOrderFetching }] = useConfirmOrderMutation();
    const [tokenizeCC, { isLoading: isTokenizedCCLoading, isFetching: isTokenizedCCFetching }] = useTokenizeCardMutation();    

    const cardTranslation = {
        'visa': 'visa',
        'discover': 'discover',
        'jcb': 'jcb',
        'mastercard': 'master',
        'american-express': 'amex'
    };

    // Local state
    const [addressModal, setAddressModal] = useState({
        type: '',
        open: false,
    });

    const toggleAddressModal = () => {
        setAddressModal({
            ...addressModal,
            open: !addressModal.open
        });
    };

    const showAlert = (error) => {
        setErrorMessage(error);
        window.scrollTo({ top: 0, behavior: 'smooth' })
    };

    useEffect(() => {
        if (searchParams.get('WebError')) {
            //remove query string
            searchParams.delete('WebError');
            setSearchParams(searchParams);
        }
    }, [errorMessage]);

    // Redirect if cart is empty
    useEffect(() => {
        if (cartDataFetched && cartDataFetched?.products?.length === 0 && !isCartFetching) {
            if (errorMessage) {
                navigate('/' + getLanguageCodeFromSession() + '/cart?WebError=' + errorMessage);
            } else {
                navigate('/' + getLanguageCodeFromSession() + '/cart');
            }
        }
    }, [isCartFetching, cartDataFetched]);

    // Set payment capabilities
    useEffect(() => {
        if (!isMeFetching && !isCartFetching && !isPaymentMethodFetching) {
            dispatch(rootAction.checkoutActions.setActivity({
                orderTotal: cart.orderTotal,
                paymentMethods,
                creditBalance: creditBalance,
                pdBalance: payrollDeduction.AvailablePDBalance,
                isPayrollDeductionEnabled,
                creditEnabled,
                isOnAccount
            }));
        }
    }, [isMeFetching, isPaymentMethodFetching, isCartFetching]);

    // Initialize address activity
    useEffect(() => {
        if (isAddressError && !isCustomerAddressesFetching) {
            //alert error
            showAlert(Config.ERROR_MESSAGES.CHECKOUT_GET_ADDRESS_ERROR);
            return;
        }

        // declare the data fetching function
        if (!isCustomerAddressesFetching && customerAddresses && (!isAddressValid(customerAddresses?.billingAddress) || !isAddressValid(customerAddresses?.shippingAddress))) {
            navigate(`/${getLanguageCodeFromSession()}/update-profile?redircode=1`);
            return;
        }
    }, [isCustomerAddressesFetching]);

    useEffect(() => {
        if (!isShippingMethodsFetching && !isCartFetching) {
            const currentShippingMethod = shippingMethods?.applicable_shipping_methods.find((sm) => shippingMethod.id === sm.id);
            const defaultShippingMethod = shippingMethods?.applicable_shipping_methods.find((sm) => sm.id === shippingMethods?.default_shipping_method_id);
            dispatch(rootAction.checkoutActions.setShippingMethod(currentShippingMethod || defaultShippingMethod));
        }
    }, [isShippingMethodsFetching, isCartFetching]);

    // Initialize address activity
    useEffect(() => {
        if (isMeFetching || isCustomerAddressesFetching || isPaymentMethodFetching || isCartFetching || isCalculatFetching)
            return;

        var billingToUse = {
            ...defaultBillingAddress,
            firstName: siteName,
            lastName: 'HQ'
        };

        var useHQ = (creditBalance + payrollDeduction?.AvailablePDBalance) > cart.orderTotal; 
        // force billing to HQ if not CreditCard
        if (useHQ) {
            if (!defaultBillingAddress || defaultBillingAddress.address1.length === 0) {
                showAlert(Config.ERROR_MESSAGES.NO_HQ_ADDRESS);
                return;
            }
        }

        var shipAddr = shippingAddress ?? customerAddresses?.shippingAddress;
        var billAddr = billingAddress ?? customerAddresses?.billingAddress;
        dispatch(rootAction.checkoutActions.setAddressActivity({
            employeeLocations,
            employeeLocation,
            siteLocations,
            defaultBillingAddress: billingToUse,
            shipToLocationEnabled,
            shipToLocations: customerAddresses?.shipToLocations,
            firstName: customerAddresses?.shippingAddress?.firstName,
            lastName: customerAddresses?.shippingAddress?.lastName,
            billingAddressEditable,
            shippingAddressEditable,
            addressBookShippingAddress: shipAddr,
            addressBookBillingAddress: useHQ ? billingToUse : billAddr,
            paymentMethods,
            customerOnAccount: loginUser?.IsOnAccount,
        }));
    }, [isMeFetching, isCustomerAddressesFetching, isPaymentMethodFetching, isCartFetching, isCalculatFetching]);

    useEffect(() => {
        dispatch(rootAction.checkoutActions.setIsInOrderReview(null));
    }, [dispatch]);

    const confirmAction = async (creditCard, tokenResponse) => {
        return await confirmOrder({
            shippingAddress: checkoutActivity.shippingAddress,
            shippingMethod: cart.shippingMethod,
            billingAddress: checkoutActivity.billingAddress,
            paymentMethods: checkoutActivity.paymentMethods,
            pdMaxDeductions: Number(checkoutActivity.payrollMaxDeductions || 0),
            ccTokenResponse: tokenResponse,
            creditCard,
            employee: {
                CreditBalance: creditBalance,
                PayrollDeduction: payrollDeduction
            },
            paymentAmounts: checkoutActivity.paymentAmounts
        }).unwrap()
            .then(() => {
                rootAction.checkoutActions.setIsInOrderReview(true);
                navigate('/' + getLanguageCodeFromSession() + '/order-review');
            })
    }
    const ConfirmCustomerOrder = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        const pdMethod = checkoutActivity.paymentMethods?.find(pm => pm.id === 'UAB_PAYROLL_DEDUCTION');
        if (pdMethod && !checkoutActivity.payrollMaxDeductions) {
            showAlert(replaceLoclizationLabel('Checkout_PayrollMaxDeductionsRequired', 'You must select a payroll deduction amount.'));
            return;
        }
        if (!stripeRef.current) {
            try{
            await confirmAction(null, null);
            return;
            }catch(err){
                showAlert(err.data.message);
                return;
            }
        }
        const ccValidation = stripeRef.current.validateCreditCard();
        if (!ccValidation) {
            return;
        }

        var ccMethod = paymentMethods.applicable_payment_methods?.find(pm => pm.id === 'CREDIT_CARD');
        if (ccMethod && ccMethod.cards.find(o => o.card_type.toLowerCase() === cardTranslation[stripeRef.current.issuer]) === undefined) {
            showAlert('Unfortunately, we don\'t accept ' + stripeRef.current.issuer.split(' ').map(function (word) { return word[0].toUpperCase() + word.substr(1); }).join(' ') + ' card at this time, Please try a different credit card type.');
            return;
        }

        try {
            const creditCard = {
                ccName: stripeRef.current.name,
                ccNum: stripeRef.current.number,
                ccExp: stripeRef.current.expiry,
                ccCvc: stripeRef.current.cvc,
                ccBrand: stripeRef.current.issuer,
            }
            if (creditCard.ccNum) {
                await tokenizeCC({
                    ...creditCard,
                    billingAddress: checkoutActivity.billingAddress,
                }).unwrap()
                    .then(async (data) => {
                        delete creditCard.ccCvc;
                        dispatch(rootAction.checkoutActions.setCreditCard(creditCard));
                        await confirmAction(creditCard, data.data);
                    })
                    .catch((err) => {
                        showAlert(err.data.message);
                    });
            }

        } catch (err) {
            showAlert(err.data.message);
        }
    }

    return (
        <>
            <Seo
                title="Checkout"
                description="Checkout"
                keywords="Checkout"
            />
            <LoadingScreen loading={isOrderLoading || isOrderFetching || isTokenizedCCLoading || isTokenizedCCFetching} />
            <SiteBreadcrumb title="Checkout" />

            {addressModal.open &&
                <AddressEditModal
                    type={addressModal.type}
                    isOpen={addressModal.open}
                    toggle={toggleAddressModal}
                    formAllowed={addressModal.editFormAllowed}
                    hasCreditCardPaymentMethod={hasCreditCardPaymentMethod}
                />}
            <form onSubmit={ConfirmCustomerOrder}>
                <section className="checkout-page pb-8 pb-md-9" id="checkout">
                    <div className="container-fluid">
                        <Alert color="danger" className="mb-0" isOpen={errorMessage != ''}>
                            {errorMessage}
                        </Alert>
                        <br />
                        <div className="section-title pb-3">
                            <h1 className="mb-0 mt-md-n2">
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "Checkout", "lbl_check_checkout")
                                    :
                                    "Checkout"
                                }
                            </h1>
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                {
                                    summaries.map((type, i) => {
                                        const { shippingAddress, billingAddress } = checkoutActivity;
                                        return <AddressSummary
                                            key={type + (type === 'shipping' ? shippingAddress?.id : billingAddress?.id)}
                                            address={type === 'shipping' ? (checkoutActivity.shippingAddress || shippingAddress) : (checkoutActivity.billingAddress || billingAddress)}
                                            email={loginUser.EmailAddress}
                                            type={type}
                                            stripeRef={type === 'billing' ? stripeRef : null}
                                            title={type === 'shipping' ? 'Shipping' : (type === 'po' ? 'Purchase Order #' : 'Billing')}
                                            subTitle={type === 'shipping' ? 'Shipping Address' : (type === 'po' ? '' : 'Billing Address')}
                                            withShippingMethods={type === 'shipping' ? true : false}
                                            setEditAddress={setAddressModal}
                                            openModal={toggleAddressModal}
                                            stepNumber={i + 1}
                                            hasCreditCardPaymentMethod={hasCreditCardPaymentMethod}
                                        />
                                    })
                                }
                                <div className='row justify-content-end'>
                                    <div className='col-12 col-md-6 col-xl-5 next-step-button pl-4 pr-4'>
                                        <button type='submit' className='btn btn-primary btn-block' id="lbl_check_placeorder" disabled={isAddressError || !checkoutActivity.paymentMethods?.length || (shipToLocationEnabled && customerAddresses?.shipToLocations.length === 0)}>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "Place Order", "lbl_check_placeorder")
                                                :
                                                "Continue to order review"
                                            }
                                        </button>
                                    </div>
                                </div >
                            </div >

                            <div className="col-md-4">
                                {!isCartFetching ?
                                    !isToggled && <OrderSummary
                                        id={`summary-${cart?.orderTotal}`}
                                        cart={cart}
                                    />
                                    :
                                    !isToggled && <ContentLoader
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                        viewBox="0 0 445 300"
                                        height={300}
                                    >
                                        <rect x="0" y="0" width="100%" height="275" />
                                    </ContentLoader>
                                }
                                <ShippingBagSummary
                                    cart={cart}
                                />
                            </div>
                        </div >
                    </div >
                </section >
            </form >
        </>
    );
}

export default Checkout;
