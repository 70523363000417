import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Collapse, Tooltip } from 'reactstrap';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { useSelector } from 'react-redux';
import { formatCurrency, makePriceRoundToTwoPlaces } from '../../../helpers/ConversionHelper';
import { formatPhoneNumber } from 'react-phone-number-input';
import ContentLoader from 'react-content-loader';
import { Fragment } from 'react';

import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import { useSwitchSessionMutation } from '../../../services/auth.ts';
//--Paypal
import GlobalEnums from '../../../helpers/GlobalEnums';
import Seo from '../../components/shared/Seo';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import rootAction from '../../../stateManagment/actions/rootAction';
import VASTemplateV2 from '../../components/products/VASTemplateV2';
import { Qualtrics } from '../../components/modal/Qualtrics';
import Config from '../../../helpers/Config.js';
import { PAYMENT_INSTRUMENTS } from '../../../helpers/Constants.js';

import { ToggleContext,useSwitching } from '../../../ToggleProvider.js';

const OrderSummary = ({ cart }) => {
    const LocalizationLabelsArray = [];
    const showAdjusted = cart?.adjustedTotal != cart?.orderTotal;
    return (
        <Card color="light" className="card-sidebar mb-5 order-summary">
            <CardHeader tag="h5">
                {LocalizationLabelsArray.length > 0 ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Order Summary", "lbl_check_ordersummary")
                    :
                    "Order Summary"
                }
            </CardHeader>
            <CardBody>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <span>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Item Total", "lbl_check_itemtotal")
                                :
                                "Item Total"
                            }
                        </span>
                        <span>{formatCurrency(cart?.cartSubTotal + cart?.savingsTotal)}</span>
                    </li>
                    {cart?.savingsTotal > 0 ? <Fragment>
                        <li className="list-group-item">
                            <span style={{ fontWeight: "bold" }} className="text-danger">
                                Discounts
                            </span>
                            <span style={{ fontWeight: "bold" }} className="text-danger">-{formatCurrency(cart?.savingsTotal)}</span>
                        </li>

                        <li className="list-group-item">
                            <span>
                                Item Subtotal
                            </span>
                            <span>{formatCurrency(cart?.cartSubTotal)}</span>
                        </li></Fragment> : <></>}

                    <li className="list-group-item">
                        <span>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Shipping cost", "lbl_check_shippingcost")
                                :
                                "Shipping cost"
                            }
                        </span>
                        <span>{formatCurrency(cart?.shippingSubTotal)}</span>
                    </li>
                    <li className="list-group-item">
                        <span>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Estimated Sales Tax", "lbl_check_estimatedsalestax")
                                :
                                "Estimated Sales Tax"
                            }
                        </span>
                        <span>{formatCurrency(showAdjusted ? cart?.adjustedTax : cart?.taxTotal)}</span>
                    </li>
                    <li className="list-group-item order-summary-total">
                        <span>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Estimated Billed Amount", "lbl_check_estimatedbilledamount")
                                :
                                "Estimated Billed Amount"
                            }
                        </span>
                        <span>
                            {formatCurrency(showAdjusted ? cart?.adjustedTotal : cart?.orderTotal)}
                        </span>
                    </li>
                    {cart?.savingsTotal > 0 ? <Fragment>
                        <div className="row order-savings justify-content-center mb-1 mb-md-4 ">
                            <span style={{ fontWeight: "bold" }} className="text-danger">Your savings:</span> &nbsp; <span style={{ fontWeight: "bold" }} className="text-danger">{formatCurrency(cart?.savingsTotal)}</span>
                        </div></Fragment> : <></>}
                </ul>
            </CardBody>
        </Card>
    )
}

const ShippingBagSummary = ({ cart }) => {
    const LocalizationLabelsArray = [];
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const [isOpenEmbroiderySummary, setIsOpenEmbroiderySummary] = useState({});
    const toggleEmbroiderySummary = (e, id) => {
        e.preventDefault();
        e.target.blur();
        setIsOpenEmbroiderySummary({
            ...isOpenEmbroiderySummary,
            [id]: !isOpenEmbroiderySummary[id]
        });
    };
    const toggleIcon = (visible) => {
        return visible ? <span className='icon icon-arrowUp ml-1' /> : <span className='icon icon-arrowDown ml-1' />;
    };
    const { isToggled, setIsToggled } = useContext(ToggleContext);

    return (
        <Card className="products-summary mb-5">
            <CardHeader className="no-bg-header">
                <h5 className='font-weight-prebold d-block d-md-none mb-0'>
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, "Shopping Bag", "lbl_check_shoppingbag")
                        :
                        "Shopping Bag"
                    }
                    &nbsp;({cart?.totalCartItems})
                </h5>
                <div className='row no-gutters align-items-center d-none d-md-flex font-weight-bold'>
                    <div className='col-6'>
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, "Product", "lbl_check_product")
                            :
                            "Product"
                        }
                    </div>
                    {!isToggled&&
                        <div className='col-3 text-center'>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_price")
                                :
                                "Price"
                            }
                        </div>
                    }
                    <div className='col text-center'>
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, "Qty", "lbl_check_qty")
                            :
                            "Qty"
                        }
                    </div>
                    {!isToggled&&
                        <div className='col-2 text-right'>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Total", "lbl_check_total")
                                :
                                "Total"
                            }
                        </div>
                    }
                </div>
            </CardHeader>
            <CardBody>
                <ul className="list-group list-group-flush">
                    {cart?.products?.map((data, idx) => (
                        <li className="list-group-item" key={idx}>
                            <div className='row no-gutters'>
                                <div className='col-12 col-md-6'>
                                    <div className='product-line-item'>
                                        <div className='product-image'>
                                            <img src={data.images[0]?.url.replace('?sw=100&sh=150&sm=fit', '?sw=162&sh=241&sm=fit')} alt={data.productName} className='img-fluid' />
                                        </div>
                                        <div className='product-info'>
                                            <div className='line-item-title'>
                                                <p className='font-weight-prebold'>
                                                    {data.productName}
                                                </p>
                                                <p>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Style", "lbl_check_style")
                                                        :
                                                        "Style"
                                                    }
                                                    &nbsp;#: {data.productId}
                                                </p>
                                            </div>

                                            <div className="line-item-summary">
                                                {data.colorName &&
                                                    <div className="attribute-item">
                                                        <span className='label'>
                                                            {LocalizationLabelsArray.length > 0 ?
                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Color", "lbl_check_color")
                                                                :
                                                                "Color"
                                                            }
                                                            :
                                                        </span>
                                                        <span>{data.ColorLongName}</span>
                                                    </div>}

                                                {data.sizeShortName &&
                                                    <div className="attribute-item">
                                                        <span className='label'>
                                                            {LocalizationLabelsArray.length > 0 ?
                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Size", "lbl_check_size")
                                                                :
                                                                "Size"
                                                            }
                                                            :
                                                        </span>
                                                        <span>{data.sizeShortName}</span>
                                                    </div>}
                                            </div>

                                            <div className="line-item-summary d-block d-md-none">
                                                {!isToggled&&
                                                <div className="summary-item">
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_price")
                                                            :
                                                            "Price"
                                                        }
                                                    </span>
                                                    <span>
                                                        {data.skuOnClearance ? <span className="font-weight-prebold text-danger">{formatCurrency((data.discountedPrice / data.quantity) - data.embroideryPrice)}/ea</span>
                                                            : <Fragment><span className="font-weight-prebold text-danger">{formatCurrency((data.discountedPrice / data.quantity) - data.embroideryPrice)}/ea</span><br /><span className="font-weight-prebold" style={{ textDecoration: "line-through" }}>{formatCurrency(data.regularPrice)}</span></Fragment>}
                                                    </span>
                                                </div>
                                                }
                                                <div className="summary-item">
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_qty")
                                                            :
                                                            "Qty"
                                                        }
                                                    </span>
                                                    <span>
                                                        {data.quantity}
                                                    </span>
                                                </div>
                                                {!isToggled&&        
                                                <div className="summary-item">
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_total")
                                                            :
                                                            "Total"
                                                        }
                                                    </span>
                                                    <span>
                                                        {formatCurrency(data.productPrice + (data.embroideryPrice * data.quantity))}
                                                    </span>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!isToggled&&
                                <div className='product-info col-3 d-none d-md-block text-center'>
                                    {data.skuOnClearance ? <span className="font-weight-prebold text-danger">{formatCurrency((data.discountedPrice / data.quantity) - data.embroideryPrice)}/ea</span>
                                        : <Fragment><span className="font-weight-prebold text-danger">{formatCurrency((data.discountedPrice / data.quantity) - data.embroideryPrice)}/ea</span><br /><span className="font-weight-prebold" style={{ textDecoration: "line-through" }}>{formatCurrency(data.regularPrice)}</span></Fragment>}
                                </div>
                                }
                                <div className='product-info col d-none d-md-block text-center'>
                                    <span className='font-weight-prebold'>
                                        {data.quantity}
                                    </span>
                                </div>
                                {!isToggled&&
                                <div className='product-info col-2 d-none d-md-block text-right'>
                                    <span className='font-weight-prebold'>
                                        {formatCurrency(data.productPrice)}
                                    </span>
                                </div>
                                }
                                {data.embroideryPrice > 0 ?
                                    <>
                                        <div className="embroidery-summary w-100">
                                            <div className='d-flex justify-content-between'>
                                                <div className='label d-flex align-items-center'>
                                                    <span className='d-none d-md-inline-block'>
                                                        <span className="icon icon-embroideryNeedle mr-3"></span>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Embroidery Added", "lbl_check_embroideryadded")
                                                            :
                                                            "Embroidery Added"
                                                        }
                                                        &nbsp;
                                                        <Button color="link" className="tooltip-icon align-middle" id="tooltip-embroidery-info" onClick={(e) => e.preventDefault()}>
                                                            <span className="icon icon-tooltip"></span>
                                                        </Button>
                                                    </span>
                                                    <span className='d-inline-block d-md-none'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Embroidery", "lbl_check_embroidery")
                                                            :
                                                            "Embroidery"
                                                        }
                                                    </span>
                                                    <Link className="btn-link link-underline d-flex align-items-center ml-3 ml-md-8" to="#" onClick={(e) => toggleEmbroiderySummary(e, data.lineItemId)}>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Details", "lbl_check_details")
                                                            :
                                                            "Details"
                                                        }
                                                        {toggleIcon(isOpenEmbroiderySummary[data.lineItemId])}
                                                    </Link>
                                                    <Tooltip
                                                        isOpen={tooltipOpen}
                                                        target="tooltip-embroidery-info"
                                                        toggle={toggleTooltip}
                                                        innerClassName="text-left"
                                                    >
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Embroidery details listed below.", "lbl_check_embroiderydetailstooltip")
                                                            :
                                                            "Embroidery details listed below."
                                                        }
                                                    </Tooltip>
                                                </div>
                                                {!isToggled&&
                                                <div className='product-info text-right'>
                                                    <span className='font-weight-prebold'>
                                                        {formatCurrency(data.embroideryPrice * data.quantity)}
                                                    </span>
                                                </div>
                                                }
                                            </div>
                                            <Collapse className='EmbSelection pt-2' isOpen={isOpenEmbroiderySummary[data.lineItemId]}>
                                                <VASTemplateV2
                                                    vasData={data.embroidery}
                                                    VasSelectionIndex={0}
                                                    ItemChecked={false}
                                                    PreviewBaseUrl={data.EmbPreviewBaseUrl}
                                                    hexCode={data.HexCode}
                                                    readOnly={true}
                                                />
                                            </Collapse>
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                            </div>
                        </li>
                    ))}
                </ul>
            </CardBody>
        </Card>
    )
}

const AddressSummary = ({ paymentInstruments, shippingMethod, shippingAddress, billingAddress, email, customerPurchaseOrder = '' }) => {
    const LocalizationLabelsArray = [];
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    return (
        <div className='card mb-5 card-summary'>
            <h5 className="card-header no-bg-header d-flex align-items-center">
                {LocalizationLabelsArray.length > 0 ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Shipping & Billing", "lbl_check_shippingandbilling")
                    :
                    "Shipping & Billing"
                }
            </h5>
            <div className="card-body">
                <div className='row'>
                    <div className='col-12 col-md-6 mb-6 mb-md-5'>
                        <div className="customer-summary">
                            <p className='label mb-0 mb-md-2'>
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "Shipping Address", "lbl_check_shippingaddress")
                                    :
                                    "Shipping Address"
                                }
                            </p>
                            {shippingAddress ?
                                <>
                                    <p>{shippingAddress.fullName}</p>
                                    <p>{shippingAddress.address1}</p>
                                    {shippingAddress.address2 ?
                                        <p>{shippingAddress.address2}</p>
                                        :
                                        null
                                    }
                                    <p>{shippingAddress.city}, {shippingAddress.stateCode} {shippingAddress.postalCode}</p>
                                    <p>{shippingAddress.countryCode === 'US' || shippingAddress.countryCode === 'USA' ? 'United States' : ''}</p>
                                </>
                                :
                                <ContentLoader
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                    viewBox="0 0 280 105"
                                    height={112}
                                >
                                    <rect x="0" y="0" width="125" height="22" />
                                    <rect x="0" y="28" width="125" height="22" />
                                    <rect x="0" y="56" width="125" height="22" />
                                    <rect x="0" y="84" width="125" height="22" />
                                </ContentLoader>
                            }
                        </div>
                    </div>

                    {customerPurchaseOrder.length > 0 &&
                        <div className='col-12 col-md-6 mb-6 mb-md-5'>
                            <div className="customer-summary">
                                <p className='label mb-0 mb-md-2'>
                                    {LocalizationLabelsArray.length > 0 ?
                                        replaceLoclizationLabel(LocalizationLabelsArray, "Purchase Order #", "lbl_check_shippingaddress")
                                        :
                                        "Purchase Order #"
                                    }
                                </p>
                                <p>{customerPurchaseOrder}</p>
                            </div>
                        </div>
                    }

                    <div className='col-12 col-md-6 mb-6 mb-md-5'>
                        <div className="customer-summary">
                            <p className='label mb-0 mb-md-2'>
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "Billing Address", "lbl_check_billingaddress")
                                    :
                                    "Billing Address"
                                }
                            </p>
                            {billingAddress ?
                                <>
                                    <p>{billingAddress.fullName}</p>
                                    <p>{billingAddress.address1}</p>
                                    {billingAddress.address2 ?
                                        <p>{billingAddress.address2}</p>
                                        :
                                        null
                                    }
                                    <p>{billingAddress.city}, {billingAddress.stateCode} {billingAddress.postalCode}</p>
                                    <p>{billingAddress.countryCode === 'US' || shippingAddress.countryCode === 'USA' ? 'United States' : ''}</p>
                                </>
                                :
                                <ContentLoader
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                    viewBox="0 0 280 105"
                                    height={112}
                                >
                                    <rect x="0" y="0" width="125" height="22" />
                                    <rect x="0" y="28" width="125" height="22" />
                                    <rect x="0" y="56" width="125" height="22" />
                                    <rect x="0" y="84" width="125" height="22" />
                                </ContentLoader>
                            }
                        </div>
                    </div>

                    <div className='col-12 col-md-6 mb-6 mb-md-5'>
                        <div className="customer-summary">
                            <p className='label mb-0 mb-md-2'>
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "Order contact information", "lbl_check_ordercontactinformation")
                                    :
                                    "Order contact information"
                                }
                            </p>
                            {shippingAddress.phone || email ?
                                <>
                                    {email ?
                                        <p>{email}</p>
                                        :
                                        null
                                    }
                                    {shippingAddress.phone ?
                                        <p>{formatPhoneNumber('+1' + shippingAddress.phone)}</p>
                                        :
                                        null
                                    }
                                </>
                                :
                                <ContentLoader
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                    viewBox="0 0 280 50"
                                    height={32}
                                >
                                    <rect x="0" y="0" width="200" height="100" />
                                </ContentLoader>
                            }
                        </div>
                    </div>

                    <div className='col-12 col-md-6 mb-6 mb-md-5'>
                        <div className="customer-summary">
                            <p className='label mb-0 mb-md-2'>
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "Payment Method", "lbl_check_paymentmethod")
                                    :
                                    "Payment Method"
                                }
                            </p>
                            {paymentInstruments ?
                                paymentInstruments.map((paymentMethod) => {
                                    if (paymentMethod.payment_method_id !== 'CREDIT_CARD') {
                                        return (
                                            <React.Fragment key={paymentMethod.payment_method_id}>
                                                <p className='mb-2'>
                                                    {PAYMENT_INSTRUMENTS[paymentMethod.payment_method_id]}{!isToggled&&<Fragment>: <span className="font-weight-bold">{formatCurrency(paymentMethod.amount)}</span></Fragment>}
                                                    {paymentMethod.payment_method_id === 'UAB_PAYROLL_DEDUCTION' ? <span className='d-block'>Max Deductions: <span className="font-weight-bold">{paymentMethod?.c_B2B_PayrollDeductionMaxDeductions}</span></span> : null}
                                                </p>
                                            </React.Fragment>
                                        )
                                    }
                                    return (
                                        <React.Fragment key={paymentMethod.payment_method_id}>
                                            <p className='mb-2'>
                                                Credit Card: <span className="font-weight-bold">{formatCurrency(paymentMethod.amount)}</span>
                                                <span className="d-block">************{paymentMethod?.payment_card.number_last_digits} Exp. {paymentMethod?.payment_card.expiration_month}/{paymentMethod?.payment_card.expiration_year}</span>
                                            </p>
                                        </React.Fragment>
                                    )
                                })
                                :
                                <ContentLoader
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                    viewBox="0 0 280 50"
                                    height={32}
                                >
                                    <rect x="0" y="0" width="200" height="100" />
                                </ContentLoader>
                            }
                        </div>
                    </div>

                    <div className='col-12 col-md-6'>
                        <div className="customer-summary">
                            <p className='label mb-0 mb-md-2'>
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "Shipping Method", "lbl_check_shippingmethod")
                                    :
                                    "Shipping Method"
                                }
                            </p>
                            {shippingMethod ?
                                !isToggled ? <Fragment>
                                <p>{shippingMethod.name}: <span className="font-weight-bold">{shippingMethod.price > 0 ? `${GetDefaultCurrencySymbol()}${makePriceRoundToTwoPlaces(shippingMethod.price)}` : 'FREE'}</span></p></Fragment>
                                :<Fragment><p>{shippingMethod.name}</p></Fragment>
                                :
                                <ContentLoader
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                    viewBox="0 0 280 50"
                                    height={32}
                                >
                                    <rect x="0" y="0" width="200" height="100" />
                                </ContentLoader>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const OrderConfirmation = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const orderState = useSelector((state) => state.orderReducer);
    const siteInfo = useSelector(state => state.commonReducer);
    const [order, setOrder] = useState({});
    const [qualtrics, setQualtrics] = useState({
        data: {},
        open: false
    });
    const [orderDate, setOrderDate] = useState('');

    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    const loginUser = useSelector(state => state.userReducer.user);
    const { showSwitching, hideSwitching } = useSwitching();
    const { isToggled, setIsToggled, intentToSwitch,setIntentToSwitch } = useContext(ToggleContext);
    const [switchSession, { isLoading }] = useSwitchSessionMutation();
    const orderConfirmationMessage = useSelector(state => state.commonReducer.orderConfirmationMessage);
    

    window.onpopstate = () => {
        if (window.location.pathname.indexOf('order-review') > -1)
            navigate('/cart');
    };
    
    useEffect(() => {
        if (!orderState?.sfccOrderNo) {
            navigate('/');
            return;
        }
        setOrder(orderState);

        orderState.paymentInstruments?.forEach((paymentInstrument) => {
            if (paymentInstrument.payment_method_id === 'UAB_EMPLOYEE_CREDITS') {
                const balance = loginUser.CreditBalance - paymentInstrument.amount;
                dispatch(rootAction.userAction.updateCreditOptimiscally(balance));
            }
            if (paymentInstrument.payment_method_id === 'UAB_PAYROLL_DEDUCTION') {
                const balance = loginUser.PayrollDeduction.AvailablePDBalance - paymentInstrument.amount;
                dispatch(rootAction.userAction.updatePayrollDeductionOptimiscally(balance));
            }
        });
        
        if (siteInfo.isQualtricsEnabled)
        {
            var qsData = {
                data: {
                    orderNo: orderState.sfccOrderNo,
                    name: orderState.shippingAddress.fullName,
                    address: orderState.shippingAddress.address1,
                    city: orderState.shippingAddress.city,
                    state: orderState.shippingAddress.stateCode,
                    zip: orderState.shippingAddress.postalCode,
                    phone: orderState.shippingAddress.phone,
                    email: loginUser.EmailAddress
                },
                open: true
            };
            var qsTimer = setTimeout(() => {
                setQualtrics(qsData);
                qsTimer = null;
            }, Config.QUALTRICS_TIMER_MS);
        }
        //Resolve order date
        var orderDateObj = new Date(order?.orderDate ? order.orderDate : new Date());
        var year = orderDateObj.getFullYear();
        console.log(orderDateObj.getMonth());
        var month = (orderDateObj.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because getMonth() returns 0-based month
        var day = orderDateObj.getDate().toString().padStart(2, '0');
        setOrderDate(`${month}/${day}/${year}`);

        return () => {
            dispatch(rootAction.orderActions.reset())
        }
    }, []);

    const toggleQualtrics = () => {
        setQualtrics({
            ...qualtrics,
            open: !qualtrics.open
        })
    };

    const switchToSelfPay = async() => {
        setIntentToSwitch(false);
        const Email = "NOOP";
        const Password = "NOOP";
        showSwitching();
        setIsToggled(!isToggled);
        // setIsLoading(true);
        switchSession({ Email, Password })
        .unwrap()
        .catch((error) => {
            //setShowError(true);
            //revert incase of error
            setIsToggled(!isToggled);
            window.location.href = '/';
            //window.location.reload();
        })
        .finally(() => {
            //  setIsLoading(false);
            window.location.href = '/';
            //window.location.reload();
            /*hideSwitching();*/
        });
    }

    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {
            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["Checkout"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);

    }, [])

    return (
        <>
            <Seo
                title="Order Confirmation"
                description="Order Confirmation"
                keywords="Order Confirmation"
            />

            <SiteBreadcrumb title="Order Confirmation" />
            <Qualtrics open={qualtrics.open} data={qualtrics.data} toggle={toggleQualtrics} />

            <section className="checkout-page pb-8 pb-md-9" id="order-confirmation">
                <div className="container-fluid">
                    <div className="section-title pb-6">
                        <h1 className="mt-md-n2">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Thank you for ordering with Uniform Advantage!", "lbl_check_youwillreceive")
                                :
                                "Thank you for ordering with Uniform Advantage!"
                            }
                        </h1>
                        <p>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "You will receive an email confirmation shortly at", "lbl_check_youwillreceive")
                                :
                                "You will receive an email confirmation shortly at"
                            }
                            &nbsp;
                            <span className='font-weight-bold'>{loginUser.EmailAddress}</span>
                        </p>
                        <p className='font-weight-bold mb-0'>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Order Number", "lbl_check_ordernumber")
                                :
                                "Order Number"
                            }
                            :&nbsp;
                            {order?.orderNumber ? order.orderNumber : "An order number will be generated shortly. A confirmation email shall be sent as soon as the order number is generated."}
                        </p>
                        <p className='font-weight-bold mb-0'>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Order Date", "lbl_check_ordernumber")
                                :
                                "Order Date"
                            }
                            :&nbsp;
                            {orderDate}
                        </p>
                        {orderConfirmationMessage&&
                        <p className='font-weight-bold mb-0'>
                            {orderConfirmationMessage}
                        </p>
                        }
                    </div>

                    {order.sfccOrderNo && <div className="row">
                        <div className={isToggled?"col-md-12":"col-md-8"}>
                            <AddressSummary
                                paymentInstruments={order?.paymentInstruments}
                                shippingMethod={order?.shippingMethod}
                                shippingAddress={order?.shippingAddress || {}}
                                billingAddress={order?.billingAddress || {}}
                                email={loginUser.EmailAddress}
                                customerPurchaseOrder={order?.customerPurchaseOrder}
                            />

                            <ShippingBagSummary
                                cart={order.cart}
                            />

                            <div className='row justify-content-end'>
                                <div className='col-12 col-md-5 col-lg-4 pb-5 pb-md-0'>
                                    {!isToggled ? <>
                                    <Link to={`/${getLanguageCodeFromSession()}/`} className="btn btn-outline-primary w-100">
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Continue Shopping", "lbl_cart_cont_shop")
                                            :
                                            "Continue Shopping"
                                        }
                                    </Link>
                                    </>:<>
                                    <Button style={{
                                        border: '1px solid black',
                                        color: 'black',
                                        backgroundColor: 'white',
                                        padding: '10px 20px',
                                        cursor: 'pointer',
                                        borderRadius: '10px', // Add this line
                                        width: '400px',
                                        height: '50px'
                                    }} onClick={switchToSelfPay}>
                                        Continue Shopping
                                    </Button>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            !isToggled&&
                            <div className="col-md-4">
                                <OrderSummary
                                    cart={order.cart}
                                />
                            </div>
                        }
                    </div>}
                </div>
            </section>
        </>
    );
}

export default OrderConfirmation;
